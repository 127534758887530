import { Button } from "./"
import Layout from "../components/ComponentLayout"
import React from "react"

export const PreorderButton = ({
  backgroundImage,
  componentDepth,
  headline,
  button,
}) => {
  // console.log(backgroundImage.childImageSharp.fluid.src);
  return (
    <Layout backgroundImage={backgroundImage} componentDepth={componentDepth} fullWidth={false} backgroundGradient="linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 50%)">
      <div className="mx-auto w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
        <h2 className="text-xl md:text-2xl text-center mx-auto uppercase mb-12 leading-tight">
          {headline}
        </h2>
        <div className="flex justify-center">
          {button?.buttonText && button?.buttonLink && (
            <Button
              href={`${button.buttonLink}`}
              className="px-16 w-full md:w-auto sm:mb-0 tracking-widest"
              type="button-light"
            >
              {button.buttonText}
            </Button>
          )}
        </div>
      </div>
    </Layout>
  )
}
