import React, { useState } from "react";
import sampleImage from "../assets/images/ForegroundImage.png";
import backgroundImage from "../assets/images/backgrounds/newsletter-background.png";
import { Modal, Button } from "./index";

export const NewsletterPopup = () => {
  const [showModal, setShowModal] = useState(false);
  // const [shownOnce, setShownOnce] = useState(false);

  // Modal will appear after 5 seconds, only once
  // setTimeout(() => {
  //   if (!shownOnce) {
  //     setShownOnce(true);
  //     setShowModal(true);
  //   }
  // }, 5000);

  return (
    <Modal
      isOpen={showModal}
      fullHeight={false}
      onClose={() => setShowModal(false)}
    >
      <div
        className="p-12 bg-black bg-cover flex"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="pr-4 md:w-1/2">
          <h3 className="uppercase text-xs text-gold mb-5 tracking-widester">
            Newsletter Sign Up
          </h3>
          <p className="text-white mb-5 text-2lg">
            Sign up to the Total War Warhammer III Newsletter for Sed posuere
            consectetur.
          </p>
          <form className="">
            <input
              type="text"
              value=""
              placeholder="Name"
              className="mb-2 block w-full bg-transparent border-white-50 border px-4 py-2"
            />
            <input
              type="email"
              value=""
              placeholder="Email"
              className="mb-4 block w-full bg-transparent border-white-50 border px-4 py-2"
            />
            <div className="mb-5">
              <input type="checkbox" className="inline-block" />{" "}
              <span className="uppercase text-white text-xs tracking-widester">
                Agree to terms &amp; conditions
              </span>
            </div>
            <Button className="px-8 font-sans font-medium w-full md:w-auto">
              Subscribe
            </Button>
          </form>
        </div>
        <div
          className="relative px-4 hidden md:block md:w-1/2"
          style={{ transform: "translateX(2rem)" }}
        >
          <img src={sampleImage} alt="" style={{ transform: "scale(1.4)" }} />
        </div>
      </div>
    </Modal>
  );
};
