import React from "react"
import Layout from "./ComponentLayout"
import { Section } from "./Section"

export const Community = ({ title, body, componentDepth, platforms =[] }) => {
  // Null is returned when no buttons are present, which doesn't fallback to default function parameter value
  // const buttonsArray = buttons || []

  return (
    <Layout componentDepth={componentDepth}>
      <div className="overflow-hidden">
        <div className="container mx-auto px-5">
        <Section>Community</Section>
        <div className="lg:flex">
          <div className="lg:w-1/2">
            {title && (
              <h2 className="text-xl md:text-4xl leading-tight uppercase mb-12">
                {title}
              </h2>
            )}
            {platforms && (
              <ul className="items-center w-full hidden lg:flex">
                {platforms.map(({ link, logo, platform }, index) => (
                  <li key={index} className="inline-block text-center md:pr-6">
                    <a
                      className="block transition duration-300 transform hover:scale-120 w-arrow"
                      target="_blank"
                      rel="noreferrer"
                      href={link}
                    >
                      <img
                        srcSet={
                          logo.childImageSharp.gatsbyImageData.images.fallback
                            .src
                        }
                        alt={logo.altText || `Total War ${platform}`}
                        className="inline-block w-2/3 md:w-full"
                      />
                    </a>
                    {/* </a> */}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="w-full lg:w-1/2">
            {body && (
              <p className="font-sans font-normal text-lg md:text-3lg text-white mb-10">
                {body}
              </p>
            )}
            {platforms && (
              <ul className="items-center w-full flex lg:hidden mb-5">
                {platforms.map(({ link, logo, platform }, index) => (
                  <li key={index} className="inline-block sm:text-center sm:pr-5">
                    <a
                      className="block transition duration-300 transform hover:scale-120 w-arrow"
                      target="_blank"
                      rel="noreferrer"
                      href={link}
                    >
                      <img
                        srcSet={
                          logo.childImageSharp.gatsbyImageData.images.fallback
                            .src
                        }
                        alt={logo.altText || `Total War ${platform}`}
                        className="inline-block w-3/4 sm:w-11/12 md:w-full"
                      />
                    </a>
                    {/* </a> */}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        </div>
      </div>
    </Layout>
  )
}
