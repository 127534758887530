
import React from "react"
import ComponentLayout from "./ComponentLayout"
import Slider from "react-slick"
import "../styles/blog-slider.css"
import { HoverAnimate } from "./HoverAnimate"
import { HoverArrow } from "."
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "./Button"
import { Content } from "./index"
import { Modal, PlayIcon } from "./"
import YouTube from "react-youtube"

const youtubeSettings = {
  height: "100%",
  width: "100%",
  playerVars: {
    autoplay: 1,
  },
}

export const EarlyAdopter = ({
  title,
  body,
  button,
  posts,
  backgroundColour,
  overflow,
  tearTop,
  tearBottom,
  componentDepth,
  backgroundType,
  backgroundImage,
  backgroundVideo,
  bottomTear,
  topTear,
}) => {

  const sliderRef = React.useRef(null)

  const [modalContent, setModalContent] = React.useState(<div></div>)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [dragging, setDragging] = React.useState(false)

  // Pass false for previous
  const changeSlide = next => {
    if (next) {
      sliderRef.current.slickNext()
    } else {
      sliderRef.current.slickPrev()
    }
  }

  const slickSettings = {
    // Large desktop
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    infinite: true,
    arrows: false,
    draggable: true,
    focusOnSelect: true,
    variableWidth: true,
    beforeChange: (current, next) => {
      setDragging(true)
      setCurrentSlide(next)
    },
    afterChange: () => {
      setDragging(false)
    },
    responsive: [
      // Mobile and tablet
      {
        breakpoint: 1024,
        settings: {
          focusOnSelect: false,
          variableWidth: false,

          // Emulating non-center mode so I can still use the .slick-center class
          centerMode: true,
          centerPadding: 0,
        },
      },
    ],
  }

  // NOTE: This is a solution for non-responsive background images. May need to abstract it out.
  const style = {}
  if (backgroundImage) {
    style.backgroundImage = `url(${backgroundImage.mediaItemUrl})`
  }

  return (
    <ComponentLayout
      overflow={overflow}
      backgroundType={backgroundType}
      backgroundVideo={backgroundVideo}
      backgroundColour={backgroundColour}
      backgroundImage={backgroundImage}
      tearTop={tearTop}
      tearBottom={tearBottom}
      fullWidth={true}
      style={style}
      bottomTear={bottomTear}
      topTear={topTear}
      componentDepth={componentDepth}
    >
      <div className="container mx-auto px-5 mb-14 mt-24 lg:mt-48">
          <div className="lg:flex">
            <div className="flex flex-col justify-center items-center md:block lg:w-2/5 lg:mr-24">
              {title && (
                <h2 className="text-xl text-center leading-tight uppercase mb-12 md:text-2xl md:text-left w-3/4 md:w-3/5 lg:w-full 2xl:w-2/3">
                  {title}
                </h2>
              )}
              {/* Button for md and up */}
              {button?.buttonText && button?.buttonLink && (
                <Button
                  href={button?.buttonLink}
                  type={button.buttonStyle}
                  className="uppercase mb-5 hidden lg:w-auto lg:inline-block lg:px-16"
                >
                  {button?.buttonText}
                </Button>
              )}
            </div>
            <div className="w-full text-center md:text-left lg:w-1/2">
              {body && (
                <Content
                  content={body}
                  className="font-inter text-white text-lg text-center md:text-left"
                  lastParagraphMargin="mb-10"
                />
              )}
              {/* button for small screens */}
              {button?.buttonText && button?.buttonLink && (
                <Button
                  href={button?.buttonLink}
                  type={button.buttonStyle}
                  className="uppercase inline-block w-full mb-5 mx-auto sm:w-auto px-10 lg:hidden"
                >
                  {button?.buttonText}
                </Button>
              )}
            </div>
          </div>
      </div>

      <div className="blog-slider w-full py-10">
        {posts && (
          <Slider {...slickSettings} ref={sliderRef}>
            {posts.map((slide, index) => (
              <div
                key={`blog-posts-slider-${index}`}
                style={{ width: 1000 }}
              >
                <Slide
                  key={`slide-item-${index}`}
                  data={slide}
                  index={index}
                  currentSlide={currentSlide}
                  dragging={dragging}
                  setModalOpen={setModalOpen}
                  setModalContent={setModalContent}
                />
              </div>
            ))}
          </Slider>
        )}
        </div>

        {/* Mobile Buttons */}
        {posts.length > 1 ? (
          <div className="container mx-auto flex items-center justify-center mb-8 px-12 font-inter sm:hidden mb-20">
            <div className="hidden sm:block text-sm mr-5">
              {currentSlide + 1} / {posts.length}
            </div>
            <button
              className="inline-block"
              type="button"
              onClick={() => {
                changeSlide(false)
              }}
            >
              <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                <HoverArrow direction="left" size="small" />
              </HoverAnimate>
            </button>
            <button
              className="inline-block ml-3"
              type="button"
              onClick={() => {
                changeSlide(true)
              }}
            >
              <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                <HoverArrow direction="right" size="small" />
              </HoverAnimate>
            </button>
          </div>
        ) : null}

        {/* Use custom arrows with custom change slide function to override default slick functionality */}
        {posts.length > 1 ? (
          <div className="container mx-auto items-center justify-end mt-8 px-12 font-inter hidden sm:flex mb-32">
            <div className="hidden sm:block text-sm mr-5">
              {currentSlide + 1} / {posts.length}
            </div>
            <button
              className="inline-block"
              type="button"
              onClick={() => {
                changeSlide(false)
              }}
            >
              <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                <HoverArrow direction="left" size="small" />
              </HoverAnimate>
            </button>
            <button
              className="inline-block ml-3"
              type="button"
              onClick={() => {
                changeSlide(true)
              }}
            >
              <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                <HoverArrow direction="right" size="small" />
              </HoverAnimate>
            </button>
          </div>
        ) : null}

        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          fullWidth={true}
        >
          {modalContent}
        </Modal>
    </ComponentLayout>
  )
}

function Slide({
  data: { caption, image, youtubeId, thumbnailVideo, placeholder },
  index,
  dragging,
  currentSlide,
  setModalContent,
  setModalOpen,
}) {
  const modalContent = youtubeId ? (
    <YouTube
      containerClassName="embed-responsive-wrapper"
      videoId={youtubeId}
      opts={youtubeSettings}
      style={{ height: "100%" }}
    />
  ) : (
    <div className="flex justify-center items-center h-full embed-responsive-wrapper">
      <GatsbyImage
        image={image?.childImageSharp?.gatsbyImageData}
        objectFit="contain"
        className="absolute inset-0 embed-responsive-item"
        alt="Full size modal media"
      />
    </div>
  )

  return (
    <div>
      <div className=" mx-5 lg:mx-12">
        <figure className="rounded origin-center" key={index}>
          <div className="blog-slider-content relative border border-frame-border">
            {/* If a youtube ID is provided, add a play button that opens a modal */}
            {/* Else, clicking anywhere on the slide should open the full size image */}
            {youtubeId ? (
              <>
                {placeholder === "Image" && (
                  <div className="embed-responsive-wrapper">
                    <div className="embed-responsive-item bg-center bg-cover">
                      <GatsbyImage
                        image={image?.childImageSharp?.gatsbyImageData}
                        objectFit="cover"
                        className="absolute inset-0"
                        alt="YouTube thumbnail"
                      />
                    </div>
                  </div>
                )}
                {placeholder === "Video" && (
                  <video autoPlay muted loop className="object-fit">
                    <source src={thumbnailVideo?.publicURL} type="video/webm" />
                    <source src={thumbnailVideo?.publicURL} type="video/mp4" />
                  </video>
                )}
                <div
                  className="centered-button"
                >
                  <PlayIcon onClick={() => {
                    if (currentSlide !== index || dragging) {
                      return
                    }
                    setModalContent(modalContent)
                    setModalOpen(true)
                  }} />
                </div>
              </>
            ) : (
              <button
                className="block w-full"
                type="button"
                onClick={() => {
                  // Don't allow the modal to open if the user has not:
                  // - Clicked on the middle slide
                  // - The slideshow is being dragged (which can sometimes trigger a click)
                  if (currentSlide !== index || dragging) {
                    return
                  }
                  setModalContent(modalContent)
                  setModalOpen(true)
                }}
              >
                <div className="embed-responsive-wrapper">
                  <div className="embed-responsive-item bg-center bg-cover">
                    <GatsbyImage
                      image={image?.childImageSharp?.gatsbyImageData}
                      objectFit="cover"
                      className="absolute inset-0"
                      alt="Media thumbnail"
                    />
                  </div>
                </div>
              </button>
            )}
          </div>
          {caption && (
          <figcaption className="uppercase font-sans tracking-widester text-xs sm:text-sm py-5">
            <span>{caption}</span>
          </figcaption> 
          )}
        </figure>
      </div>
    </div>
  )
}