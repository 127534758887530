import React from "react";
import { titleToID } from "../utils/anchor";
import { useRefs } from "../context/context";
import { useInView } from "react-intersection-observer";

export const Anchor = ({ title, index }) => {
  const { refs, setRefs } = useRefs();
  const { ref, inView } = useInView({
    threshold: 0.90,
  });

  const anchorTitle = titleToID(title);
  const refObject = { title: anchorTitle, inView, index }

  React.useEffect(() => {
    const interimRefs = [...refs].filter(item => item.title !== anchorTitle).filter(item => item.inView)
    setRefs([...interimRefs, refObject].sort((a, b) => a.index - b.index))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);
  return <div className="clear-both" id={anchorTitle} ref={ref}></div>;
};
