import React, { useEffect } from "react"
import SteamHighlightLogo from "./SteamHighlightLogo"
import EpicHighlightLogo from "./EpicHighlightLogo"
import WindowsHighlightLogo from "./WindowsHighlightLogo"
import Accordion from "react-bootstrap/Accordion"
import "../styles/primary-nav.css"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const HeaderMenu = ({ persistLogo }) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const {
    site: {
      siteMetadata: {
        header: {
          links: { windowsLink, steamLink, epicLink, logoLink },
          menu,
        },
      },
    },
    menuImage1,
    menuImage2,
    menuImage3,
  } = useStaticQuery(headerQuery)

  const menuImages = [menuImage1, menuImage2, menuImage3]

  // Set references that are attached to the elemens that will animate
  const logoRef = React.useRef()
  const windowsRef = React.useRef()
  const steamRef = React.useRef()
  const epicRef = React.useRef()
  const [activeMenu, setActiveMenu] = React.useState("/")

  // Update active link
  useEffect(() => {
    setActiveMenu(window.location.pathname)
  }, [])

  return (
    <header className="fixed top-0 w-full z-20">
      <Accordion>
        <nav role="navigation">
          <div className="px-5 pt-5 md:px-8 md:pt-8 lg:px-12 lg:pt-12 mx-auto flex justify-between w-full">
            <ul className="flex justify-around items-center">
              <li
                ref={logoRef}
                className={`flex-grow text-center z-30 transition duration-300 hover:opacity-75`}
              >
                <AniLink
                  onClick={() => {
                    // If home screen, scroll to top
                    if (window.location.pathname === "/") {
                      window.scrollTo(0, 0)
                    }
                  }}
                  cover
                  direction="right"
                  duration={1.5}
                  bg="#000000"
                  className="inline-block cursor-pointer"
                  to={logoLink}
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../assets/images/WHIII.png"
                    alt="WH3 Logo"
                    className="w-22 sm:w-40"
                    placeholder="none"
                  />
                </AniLink>
              </li>
            </ul>
            <ul className="relative flex flex-shrink items-center overflow-hidden">
              <li ref={windowsRef} className="pl-1px pr-1px">
                <a
                  className="button-instant-svg bg-black flex items-center justify-center w-12 h-12 sm:w-20 sm:h-20"
                  href={windowsLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">Pre-order on Windows</span>
                  <WindowsHighlightLogo />
                </a>
              </li>
              <li ref={epicRef} className="pl-1px pr-1px">
                <a
                  className="button-instant-svg bg-black flex items-center justify-center w-12 h-12 sm:w-20 sm:h-20"
                  href={epicLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">Pre-order on Epic</span>
                  <EpicHighlightLogo />
                </a>
              </li>
              <li ref={steamRef} className="pl-1px pr-1px">
                <a
                  className="button-instant-svg bg-black flex items-center justify-center w-12 h-12 sm:w-20 sm:h-20"
                  href={steamLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">Pre-order on Steam</span>
                  <SteamHighlightLogo />
                </a>
              </li>

              <li className="pl-1px h-full z-30">
                <Accordion.Toggle
                  as="button"
                  variant="link"
                  eventKey="primary-nav"
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="no-outline button-instant-background bg-black flex items-center justify-center w-12 h-12 sm:w-20 sm:h-20"
                >
                  <div
                    className={`w-6 hamburger relative cursor-pointer transform scale-75 sm:scale-100 ${
                      menuOpen ? "hamburger--open" : ""
                    }`}
                  >
                    <span className="hamburger-inner">
                      <div className="sr-only">Primary menu button</div>
                    </span>
                  </div>
                </Accordion.Toggle>
              </li>
            </ul>
          </div>
          <Accordion.Collapse
            className="primary-nav absolute w-full z-10 top-0"
            eventKey="primary-nav"
          >
            {menu && (
              <ul className="primary-nav-grid flex flex-col md:flex-row h-screen">
                {menu.map(
                  ({ background, pageName, pageLink, pageContent }, index) => {
                    const external = pageLink.indexOf("http") === 0
                    const activePage = activeMenu === pageLink
                    return (
                      <li
                        className={`border-animate-top-hover primary-nav-grid-item overflow-hidden bg-black primary-nav-grid-item-${
                          index + 1
                        } w-full h-full`}
                        key={`meni-item-${index}`}
                      >
                        {external ? (
                          <a
                            key={index}
                            href={pageLink}
                            className={`flex justify-center lg:justify-start items-end w-full h-full transition duration-300 bg-cover bg-center transform ${
                              !activePage ? "hover:scale-105" : "cursor-default"
                            }`}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              backgroundImage: `url("${menuImages[index].childImageSharp.gatsbyImageData.images.fallback.src}")`,
                            }}
                          >
                            <span className="sr-only">{pageName}</span>
                          </a>
                        ) : (
                          <AniLink
                            cover
                            direction="right"
                            duration={1.5}
                            bg="#000000"
                            key={index}
                            to={pageLink}
                            className={`flex justify-center lg:justify-start items-end w-full h-full transition duration-300 bg-cover bg-center transform ${
                              !activePage ? "hover:scale-105" : "cursor-default"
                            }`}
                            style={{
                              backgroundImage: `url("${menuImages[index].childImageSharp.gatsbyImageData.images.fallback.src}")`,
                            }}
                          >
                            <span className="sr-only">{pageName}</span>
                          </AniLink>
                        )}
                        <div
                          className={`${
                            activePage
                              ? "border-animate border-animate-top"
                              : "border-child"
                          } absolute p-2 md:p-5 lg:p-7 xl:p-8 left-0 bottom-0 bg-semi-black w-full xl:w-11/12 text-center md:text-left md:min-h-nav-content`}
                        >
                          <h1 className="uppercase text-xl md:mb-3 -mt-1 lg:-mt-2">
                            {pageName}
                          </h1>
                          <p className="hidden md:block font-sans text-base xl:text-normal">
                            {pageContent}
                          </p>
                        </div>
                      </li>
                    )
                  }
                )}
              </ul>
            )}
          </Accordion.Collapse>
        </nav>
      </Accordion>
    </header>
  )
}

const headerQuery = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        header {
          menu {
            pageLink
            pageName
            pageContent
          }
          links {
            windowsLink
            epicLink
            steamLink
            logoLink
          }
        }
      }
    }
    menuImage1: file(relativePath: { eq: "images/menu_image_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    menuImage2: file(relativePath: { eq: "images/menu_image_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    menuImage3: file(relativePath: { eq: "images/menu_image_3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
