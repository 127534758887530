import React, { useRef, useState } from "react"
import Slider from "react-slick"
import _ from "lodash"
import { HoverArrow } from "./"
import LeftLaurel from "../assets/images/icons/leftlaurel.png"
import RightLaurel from "../assets/images/icons/rightlaurel.png"
import "../styles/accolades.css"
import { useMediaQuery } from "react-responsive"
import Layout from "./ComponentLayout"
import { HoverAnimate } from "./HoverAnimate"
import { GatsbyImage } from "gatsby-plugin-image"

// Switch this to be just `Accolades`
export const Accolades = ({
  accolades,
  overflow,
  tear,
  tearType,
  tearDepth,
  componentDepth,
  backgroundColour,
  bottomTear,
  topTear,
}) => {
  const accoladesRef = useRef(null)

  const [activeIndex, setActiveIndex] = useState(0)
  const [fadingIndex, setFadingIndex] = useState(null)
  const [arrowsEnabled, setArrowsEnabled] = useState(true)

  // Pass false for previous
  const changeSlide = next => {
    if (!arrowsEnabled) return

    // transition out current accolades
    setFadingIndex(activeIndex)
    setArrowsEnabled(false)

    // change slide
    setTimeout(() => {
      if (next) {
        accoladesRef.current.slickNext()
      } else {
        accoladesRef.current.slickPrev()
      }
    }, 100)
  }

  const slickSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    fade: true,
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    className: "slider-accolades flex items-center py-24",
    afterChange: newIndex => {
      setActiveIndex(newIndex)
      setFadingIndex(null)
      setArrowsEnabled(true)
    },
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          arrows: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          arrows: false,
          fade: false,
        },
      },
    ],
  }

  // Convert the 'slides' into sets of 3 for tablet+
  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)",
  })
  let slides = [...accolades]

  if (isMobile) {
    slides = _.chunk(slides, 1)
  } else {
    // If there are less than 3 slides at the end, then add more to make it divisible by 3
    const addMoreSlides = 3 - (slides.length % 3)

    if (addMoreSlides < 3) {
      for (let index = 0; index < addMoreSlides; index++) {
        slides.push(slides[index])
      }
    }

    // Chunk the slides into arrays of 3
    slides = _.chunk(slides, 3)
  }

  // console.log('slides', slides);

  return (
    <Layout
      backgroundType="Hex"
      backgroundColour={backgroundColour}
      overflow={overflow}
      tear={tear}
      tearType={tearType}
      tearDepth={tearDepth}
      fullWidth={true}
      componentDepth={componentDepth}
      className="my-auto relative"
      bottomTear={bottomTear}
      topTear={topTear}
    >
      <div className="slider-accolades">
        <Slider {...slickSettings} ref={accoladesRef}>
          {slides.map((slideAccolades, index) => {
            let active = activeIndex === index
            let fading = fadingIndex === index
            return (
              <div key={index}>
                <div className="container mx-auto flex justify-center lg:px-16">
                  {slideAccolades.map(
                    ({ stars, rating, comment, logo, image }, index) => {
                      let delay = 0 + index * 33
                      // Fade out on tablet+ only, makes little sense on mobile
                      let fadeClasses = `${
                        active ? "slide-fade-in" : "opacity-0"
                      } ${fading ? "slide-fade-out" : ""} slide-delay-${delay}`

                      return (
                        <div
                          className={`lg:w-1/3 justify-between text-center ${
                            isMobile ? "" : fadeClasses
                          }`}
                          key={index}
                        >
                          <div className="flex flex-col h-full justify-between items-center">
                            <div className="">
                              {stars ? (
                                <StarRating stars={stars} />
                              ) : (
                                <div className="flex justify-center">
                                  <img src={LeftLaurel} alt="" />
                                  <p className="text-2xl px-2 m-0 flex items-center">
                                    {rating && rating}
                                    {image && (
                                      <GatsbyImage
                                        image={
                                          image?.childImageSharp
                                            ?.gatsbyImageData
                                        }
                                        className="w-30"
                                        alt=""
                                      />
                                    )}
                                  </p>
                                  <img src={RightLaurel} alt="" />
                                </div>
                              )}
                            </div>
                            <div className="w-78 mx-auto max-w-4/5">
                              <p className="text-base text-light-grey-1 font-sans font-normal py-4">
                                {`"${comment}"`}
                              </p>
                            </div>
                            {logo?.publicURL ? (
                              <div className="py-2 flex flex-col justify-end">
                                <img
                                  src={logo.publicURL}
                                  className="mx-auto"
                                  alt={logo.altText}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            )
          })}
        </Slider>
        {/* Use custom arrows with custom change slide function to override default slick functionality */}
        {slides.length > 1 ? (
          <>
            <button
              className="block slick-arrow slick-prev"
              onClick={() => {
                changeSlide(false)
              }}
            >
              <HoverAnimate extraClasses="arrow-circle arrow-circle-small">
                <HoverArrow direction="left" size="small" />
              </HoverAnimate>
            </button>
            <button
              className="block slick-arrow slick-next"
              onClick={() => {
                changeSlide(true)
              }}
            >
              <HoverAnimate extraClasses="arrow-circle arrow-circle-small">
                <HoverArrow direction="right" size="small" />
              </HoverAnimate>
            </button>
          </>
        ) : null}
      </div>
    </Layout>
  )
}

const Star = ({ fill }) => {
  switch (fill) {
    case "full":
      return (
        <svg
          width="34"
          height="33"
          viewBox="0 0 34 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-1"
        >
          <path
            d="M16.0489 0.927051C16.3483 0.00574017 17.6517 0.00574017 17.9511 0.927051L21.2658 11.1287C21.3996 11.5407 21.7836 11.8197 22.2168 11.8197H32.9434C33.9122 11.8197 34.3149 13.0593 33.5312 13.6287L24.8532 19.9336C24.5027 20.1883 24.3561 20.6396 24.4899 21.0517L27.8046 31.2533C28.104 32.1746 27.0495 32.9407 26.2658 32.3713L17.5878 26.0664C17.2373 25.8117 16.7627 25.8117 16.4122 26.0664L7.73419 32.3713C6.95048 32.9407 5.896 32.1746 6.19535 31.2533L9.51006 21.0517C9.64393 20.6396 9.49728 20.1883 9.14679 19.9336L0.468768 13.6287C-0.314945 13.0593 0.0878303 11.8197 1.05655 11.8197H11.7832C12.2164 11.8197 12.6004 11.5407 12.7342 11.1287L16.0489 0.927051Z"
            fill="white"
          />
        </svg>
      )
    case "half":
      return (
        <>
          {" "}
          <svg
            width="17"
            height="33"
            viewBox="0 0 17 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.0489 0.927051C16.3483 0.00574017 17.6517 0.00574017 17.9511 0.927051L21.2658 11.1287C21.3996 11.5407 21.7836 11.8197 22.2168 11.8197H32.9434C33.9122 11.8197 34.3149 13.0593 33.5312 13.6287L24.8532 19.9336C24.5027 20.1883 24.3561 20.6396 24.4899 21.0517L27.8046 31.2533C28.104 32.1746 27.0495 32.9407 26.2658 32.3713L17.5878 26.0664C17.2373 25.8117 16.7627 25.8117 16.4122 26.0664L7.73419 32.3713C6.95048 32.9407 5.896 32.1746 6.19535 31.2533L9.51006 21.0517C9.64393 20.6396 9.49728 20.1883 9.14679 19.9336L0.468768 13.6287C-0.314945 13.0593 0.0878303 11.8197 1.05655 11.8197H11.7832C12.2164 11.8197 12.6004 11.5407 12.7342 11.1287L16.0489 0.927051Z"
              fill="white"
            />
          </svg>
          <svg
            width="34"
            height="33"
            viewBox="17 0 34 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.0489 0.927051C16.3483 0.00574017 17.6517 0.00574017 17.9511 0.927051L21.2658 11.1287C21.3996 11.5407 21.7836 11.8197 22.2168 11.8197H32.9434C33.9122 11.8197 34.3149 13.0593 33.5312 13.6287L24.8532 19.9336C24.5027 20.1883 24.3561 20.6396 24.4899 21.0517L27.8046 31.2533C28.104 32.1746 27.0495 32.9407 26.2658 32.3713L17.5878 26.0664C17.2373 25.8117 16.7627 25.8117 16.4122 26.0664L7.73419 32.3713C6.95048 32.9407 5.896 32.1746 6.19535 31.2533L9.51006 21.0517C9.64393 20.6396 9.49728 20.1883 9.14679 19.9336L0.468768 13.6287C-0.314945 13.0593 0.0878303 11.8197 1.05655 11.8197H11.7832C12.2164 11.8197 12.6004 11.5407 12.7342 11.1287L16.0489 0.927051Z"
              fill="#3A3A3A"
            />
          </svg>
        </>
      )
    case "empty":
      return (
        <svg
          width="34"
          height="33"
          viewBox="0 0 34 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.0489 0.927051C16.3483 0.00574017 17.6517 0.00574017 17.9511 0.927051L21.2658 11.1287C21.3996 11.5407 21.7836 11.8197 22.2168 11.8197H32.9434C33.9122 11.8197 34.3149 13.0593 33.5312 13.6287L24.8532 19.9336C24.5027 20.1883 24.3561 20.6396 24.4899 21.0517L27.8046 31.2533C28.104 32.1746 27.0495 32.9407 26.2658 32.3713L17.5878 26.0664C17.2373 25.8117 16.7627 25.8117 16.4122 26.0664L7.73419 32.3713C6.95048 32.9407 5.896 32.1746 6.19535 31.2533L9.51006 21.0517C9.64393 20.6396 9.49728 20.1883 9.14679 19.9336L0.468768 13.6287C-0.314945 13.0593 0.0878303 11.8197 1.05655 11.8197H11.7832C12.2164 11.8197 12.6004 11.5407 12.7342 11.1287L16.0489 0.927051Z"
            fill="#3A3A3A"
          />
        </svg>
      )
    default:
      return <></>
  }
}

export const StarRating = ({ stars }) => {
  let StarsArray = [...Array(Math.floor(stars))].map(_ => "full")

  // Check if there is a half star
  if (stars % 1 !== 0) {
    StarsArray.push("half")
  }
  // Check if the stars are less than 5 (if there is a half then that counts for one of the stars)
  if (Math.round(stars) !== 5) {
    const EmptyStars = Number.isInteger(5 - stars)
      ? [...Array(5 - stars)].map(_ => "empty")
      : [...Array(4 - Math.floor(stars))].map(_ => "empty")
    StarsArray = [...StarsArray, ...EmptyStars]
  }

  return (
    <figure className="flex justify-center py-6">
      <figcaption className="sr-only">{stars} star rating</figcaption>
      {StarsArray.map((value, index) => (
        <Star fill={value} key={`star-${index}`} />
      ))}
    </figure>
  )
}
