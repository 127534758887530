import React from "react";

export default function SteamHighlightLogo() {
  return <svg
    className="w-5 h-5 sm:w-8 sm:h-8"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.699616 22.445C2.82359 29.5299 9.39383 34.6918 17.1699 34.6918C26.6645 34.6918 34.3617 26.9946 34.3617 17.5C34.3617 8.00519 26.6645 0.308105 17.1699 0.308105C8.05877 0.308105 0.603696 7.39573 0.0148926 16.3578C1.12743 18.226 1.56157 19.3815 0.699778 22.445H0.699616Z"
      fill="white"
      className="change-white-to-black"
    />
    <path
      d="M16.2843 13.1942C16.2843 13.2231 16.2843 13.252 16.2858 13.2792L12.0789 19.3903C11.3974 19.3593 10.7136 19.4789 10.0652 19.746C9.77931 19.8626 9.51147 20.0057 9.26016 20.171L0.0224465 16.3681C0.0225188 16.3681 -0.191308 19.8847 0.699496 22.5055L7.23005 25.2001C7.55795 26.6655 8.56319 27.9508 10.0463 28.5692C12.4728 29.583 15.2705 28.4275 16.2799 26C16.5426 25.3654 16.6651 24.6999 16.6474 24.0358L22.6667 19.7342C22.7154 19.7356 22.7656 19.7371 22.8143 19.7371C26.4165 19.7371 29.3443 16.8005 29.3443 13.1942C29.3443 9.58757 26.4165 6.65359 22.8143 6.65359C19.2136 6.65359 16.2843 9.58757 16.2843 13.1942ZM15.275 25.5779C14.4937 27.4535 12.3383 28.3434 10.4648 27.5627C9.60046 27.2027 8.94791 26.543 8.57146 25.7565L10.6972 26.6375C12.0789 27.213 13.6641 26.5578 14.2384 25.1765C14.8145 23.7938 14.161 22.206 12.78 21.6304L10.5825 20.7199C11.4305 20.3982 12.3944 20.3864 13.295 20.7613C14.203 21.139 14.9075 21.8503 15.2809 22.7593C15.6542 23.6684 15.6527 24.6719 15.275 25.5779ZM22.8143 17.5531C20.4163 17.5531 18.4639 15.5978 18.4639 13.1942C18.4639 10.7925 20.4163 8.83659 22.8143 8.83659C25.2138 8.83659 27.1661 10.7925 27.1661 13.1942C27.1661 15.5978 25.2138 17.5531 22.8143 17.5531ZM19.5545 13.1875C19.5545 11.3794 21.0184 9.91282 22.8217 9.91282C24.6264 9.91282 26.0903 11.3794 26.0903 13.1875C26.0903 14.9959 24.6264 16.4611 22.8217 16.4611C21.0184 16.4611 19.5545 14.9959 19.5545 13.1875Z"
      fill="black"
      className="change-black-to-white"
    />
  </svg>
}