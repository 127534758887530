import React from "react";
import "../styles/arrows.css";

export const Arrow = ({ className, direction = "right" }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`arrow ${className}`}
      style={{
        transform: `rotate(${direction === "right" ? "180deg" : "0"})`,
      }}
    >
      <path d="M0.500003 30C0.500004 13.7076 13.7076 0.500007 30 0.500009C46.2924 0.50001 59.5 13.7076 59.5 30C59.5 46.2924 46.2924 59.5 30 59.5C13.7076 59.5 0.500001 46.2924 0.500003 30Z" />
      <path d="M29.1 39L20.1 30L29.1 21" />
      <path d="M20.7 29.7002L39.6 29.7002" />
    </svg>
  );
};
