import React from "react"
import Layout from "./ComponentLayout"
import { Button } from "./Button"

export const PageNotFound = ({
  backgroundType,
  backgroundVideo,
  backgroundImage,
  button
}) => {
  return (
    <Layout
      backgroundType={backgroundType}
      backgroundVideo={backgroundVideo}
      backgroundImage={backgroundImage}
    >
      <div className="w-full flex flex-col items-center justify-center relative min-h-screen">
        <div
          className="absolute h-full z-10 bottom-0 left-0 right-0"
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%)",
          }}
        ></div>
        <div className="z-20">
          <h1 className="mb-20 text-4xl">404 - Page Not Found</h1>

          <div className="flex z-20 mb-10 justify-center">
            {button?.buttonText && button?.buttonLink && (
              <Button
                href={button?.buttonLink}
                type={button.buttonStyle}
                className="uppercase w-2/3 mb-5"
              >
                {button?.buttonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
