import React from "react"
import Layout from "./ComponentLayout"
import "../styles/image-panels.css"
import Slider from "react-slick"
import { Modal, PlayIcon, Section } from "./"
import YouTube from "react-youtube"

const slickSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  // centerPadding: "15%",
  arrows: false,
  draggable: true,
  focusOnSelect: false,
  infinite: false,
  className: "slider-latest flex",
  responsive: [
    {
      breakpoint: 1024, // This isn't mobile first, so activate the the slider from 768 downwards
    },
    {
      breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
      settings: "unslick",
    },
  ],
}

export const ImagePanels = ({ slides }) => {
  const [modalContent, setModalContent] = React.useState(<div></div>)
  const [modalOpen, setModalOpen] = React.useState(false)

  return (
    <Layout fullWidth={false}>
      {/* New section heading component */}
      <Section>Latest media</Section>

      <Slider {...slickSettings} style={{ marginLeft: "-15%" }}>
        {slides.map((slide, slideIndex) => {
          const { title, category, image, youtubeId } = slide || {}
          const modalContent = (
            <YouTube
              containerClassName="embed-responsive-wrapper"
              videoId={youtubeId}
              opts={{
                height: "100%",
                width: "100%",
                playerVars: {
                  autoplay: 1,
                },
              }}
              style={{ height: "100%" }}
            />
          )

          return (
            <div
              key={`slide-${slideIndex}`}
              target="_blank"
              className="panel-container relative"
            >
              <div
                className="panel mx-3 md:mx-0 flex frame-1 shadow-inset text-left"
                style={{
                  backgroundImage: `url("${image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}")`,
                }}
              >
                <div className="panel-content w-full h-full">
                  <h3 className="text-xl leading-tight mb-3">{title}</h3>
                  <p className="font-sans tracking-widest">{category}</p>
                </div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <PlayIcon
                    onClick={() => {
                      setModalContent(modalContent)
                      setModalOpen(true)
                    }}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </Slider>

      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth={true}
      >
        {modalContent}
      </Modal>
    </Layout>
  )
}
