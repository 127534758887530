import React from "react"
import ComponentLayout from "./ComponentLayout"
import Slider from "react-slick"
import "../styles/blog-slider.css"
import { HoverAnimate } from "./HoverAnimate"
import { HoverArrow } from "."
import { GatsbyImage } from "gatsby-plugin-image"

export const BlogPosts = ({
  link,
  label,
  posts,
  backgroundColour,
  overflow,
  tearTop,
  tearBottom,
  featuredBlogPost,
  componentDepth,
  backgroundType,
  backgroundImage,
  backgroundVideo,
  bottomTear,
  topTear,
}) => {
  const sliderRef = React.useRef(null)

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [dragging, setDragging] = React.useState(false)

  // Pass false for previous
  const changeSlide = next => {
    if (next) {
      sliderRef.current.slickNext()
    } else {
      sliderRef.current.slickPrev()
    }
  }

  const slickSettings = {
    // Large desktop
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    infinite: true,
    arrows: false,
    draggable: true,
    focusOnSelect: true,
    variableWidth: true,
    beforeChange: (current, next) => {
      setDragging(true)
      setCurrentSlide(next)
    },
    afterChange: () => {
      setDragging(false)
    },
    responsive: [
      // Mobile and tablet
      {
        breakpoint: 1024,
        settings: {
          focusOnSelect: false,
          variableWidth: false,

          // Emulating non-center mode so I can still use the .slick-center class
          centerMode: true,
          centerPadding: 0,
        },
      },
    ],
  }

  // NOTE: This is a solution for non-responsive background images. May need to abstract it out.
  const style = {}
  if (backgroundImage) {
    style.backgroundImage = `url(${backgroundImage.mediaItemUrl})`
  }

  return (
    <ComponentLayout
      overflow={overflow}
      backgroundType={backgroundType}
      backgroundVideo={backgroundVideo}
      backgroundColour={backgroundColour}
      backgroundImage={backgroundImage}
      tearTop={tearTop}
      tearBottom={tearBottom}
      fullWidth={true}
      style={style}
      bottomTear={bottomTear}
      topTear={topTear}
      componentDepth={componentDepth}
    >
      <div className="blog-slider w-full py-24">
        <div className="container mx-auto lg:ml-auto w-full px-5 flex justify-start mb-8 lg:mb-0">
          <h2 className="font-inter text-light-grey-1 uppercase flex items-center tracking-widest mb-10">
            <span className="inline-block border-t border-light-grey-2 w-5 md:w-10 lg:w-30 mr-5"></span>{" "}
            Blog
          </h2>

          {/* Use custom arrows with custom change slide function to override default slick functionality */}
          {posts.length > 1 ? (
            <div className="container mx-auto items-center justify-end mb-8 lg:mb-14 px-12 font-inter hidden sm:flex">
              <div className="hidden sm:block text-sm mr-5">
                {currentSlide + 1} / {posts.length}
              </div>
              <button
                className="inline-block"
                type="button"
                onClick={() => {
                  changeSlide(false)
                }}
              >
                <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                  <HoverArrow direction="left" size="small" />
                </HoverAnimate>
              </button>
              <button
                className="inline-block ml-3"
                type="button"
                onClick={() => {
                  changeSlide(true)
                }}
              >
                <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                  <HoverArrow direction="right" size="small" />
                </HoverAnimate>
              </button>
            </div>
          ) : null}
        </div>
        {posts && (
          <Slider {...slickSettings} ref={sliderRef}>
            {posts.map((slide, slideIndex) => (
              <div
                key={`blog-posts-slider-${slideIndex}`}
                style={{ width: 1000 }}
              >
                <Slide
                  key={`slide-item-${slideIndex}`}
                  data={slide}
                  slideIndex={slideIndex}
                  currentSlide={currentSlide}
                  dragging={dragging}
                />
              </div>
            ))}
          </Slider>
        )}
        {/* Mobile Buttons */}
        {posts.length > 1 ? (
          <div className="container mx-auto flex items-center justify-center mt-8 px-12 font-inter sm:hidden">
            <div className="hidden sm:block text-sm mr-5">
              {currentSlide + 1} / {posts.length}
            </div>
            <button
              className="inline-block"
              type="button"
              onClick={() => {
                changeSlide(false)
              }}
            >
              <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                <HoverArrow direction="left" size="small" />
              </HoverAnimate>
            </button>
            <button
              className="inline-block ml-3"
              type="button"
              onClick={() => {
                changeSlide(true)
              }}
            >
              <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                <HoverArrow direction="right" size="small" />
              </HoverAnimate>
            </button>
          </div>
        ) : null}
      </div>
    </ComponentLayout>
  )
}

const Slide = ({
  dragging,
  data: { publisher, headline, link, image },
  slideIndex,
  currentSlide,
}) => {
  return (
    <div className="mx-5 lg:mx-12">
      <button
        type="button"
        rel="noreferrer"
        className="h-full w-full block font-sans"
        // Don't link out if clicking on the edge slides
        onClick={event => {
          if (slideIndex !== currentSlide || dragging) {
            event.preventDefault()
          } else {
            window.open(link)
          }
        }}
      >
        <div>
          <div className={`relative border-animate-bottom-hover`}>
            {image && (
              <div className="embed-responsive-wrapper">
                <div className="embed-responsive-item">
                  <GatsbyImage
                    image={image?.childImageSharp?.gatsbyImageData}
                    className="absolute inset-0"
                    objectFit="cover"
                    alt=""
                  />
                </div>
              </div>
            )}

            {/* <div className="lg:absolute lg:w-1/2 right-0 bottom-0 bg-black bg-opacity-80 text-white p-8 font-inter text-left">
              {date && <p className="mb-5">{date}</p>}

              {publisher && (
                <h2 className="text-normal leading-tight mb-3">{publisher}</h2>
              )}
              {headline && <p className="text-2lg font-medium">{headline}</p>}
            </div> */}
          </div>
        </div>
      </button>
    </div>
  )
}
