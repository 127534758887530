import React from "react"
import Layout from "./ComponentLayout"

export const Conversation = ({
  backgroundImage,
  backgroundColour,
  label,
  body,
  overflow,
  componentDepth,
  tearBottom,
  tearTop,
  platforms = [],
  topTear,
  bottomTear,
}) => {
  return (
    <Layout
      overflow={overflow}
      tearBottom={tearBottom}
      tearTop={tearTop}
      componentDepth={componentDepth}
      backgroundImage={backgroundImage}
      backgroundColour={backgroundColour}
      backgroundGradient="linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 44.27%)"
      fullWidth={false}
      topTear={topTear}
      bottomTear={bottomTear}
    >
      
      <div className="flex flex-col items-center w-full">
        <div className="md:w-1/2" style={{ marginBottom: "4.375rem" }}>
          <div className="w-full">
            <h2 className="text-xl sm:text-1xl lg:text-2xl uppercase mb-8 text-center mx-auto pt-5 md:pt-0 leading-tight">
              {label}
            </h2>
            <p className="font-sans text-white text-2lg mb-5 font-normal text-center">
              {body}
            </p>
            {platforms && (
              <ul className="flex justify-center items-center w-full">
                {platforms.map(({ link, logo, platform }, index) => (
                  <li key={index} className="inline-block text-center md:px-3">
                    <a
                      className="block transition duration-300 transform hover:scale-120 w-arrow"
                      target="_blank"
                      rel="noreferrer"
                      href={link}
                    >
                      <img
                        srcSet={
                          logo.childImageSharp.gatsbyImageData.images.fallback
                            .src
                        }
                        alt={logo.altText || `Total War ${platform}`}
                        className="inline-block w-2/3 md:w-full"
                      />
                    </a>
                    {/* </a> */}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
