import React from "react"
import Layout from "./ComponentLayout"
import { Button } from "./Button"
import { GatsbyImage } from "gatsby-plugin-image"
import { Content } from "./index"

export const GamePass = ({
  title,
  body,
  componentDepth,
  backgroundImage,
  button,
  image,
}) => {
  return (
    <Layout
      backgroundImage={backgroundImage}
      componentDepth={componentDepth}
      backgroundGradient="linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 50%)"
    >
      <div className="overflow-hidden">
        <div className="container mx-auto px-5 mt-12 lg:mt-24">
          <div className="lg:flex">
            <div className="lg:w-1/2">
              {title && (
                <h2 className="text-xl leading-tight uppercase mb-12 text-left md:w-1/2 md:text-2xl lg:w-3/4 xl:w-7/12">
                  {title}
                </h2>
              )}
              {/* Button for md and up */}
              {button?.buttonText && button?.buttonLink && (
                <Button
                  href={button?.buttonLink}
                  type={button.buttonStyle}
                  className="uppercase w-full mb-5 hidden lg:block md:w-1/2 lg:w-3/5 xl:w-2/5"
                >
                  {button?.buttonText}
                </Button>
              )}
            </div>
            <div className="w-full lg:w-1/2">
              {body && (
                <Content
                  content={body}
                  className="font-inter text-white text-lg lg:text-left"
                  lastParagraphMargin="mb-10"
                />
              )}
              {/* button for small screens */}
              {button?.buttonText && button?.buttonLink && (
                <Button
                  href={button?.buttonLink}
                  type={button.buttonStyle}
                  className="uppercase block w-full mb-5 lg:hidden md:w-2/5"
                >
                  {button?.buttonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {image && (
        <div className="relative">
          <GatsbyImage
            image={image?.childImageSharp?.gatsbyImageData}
            objectFit="cover"
            alt="PC Game Pass"
          />
          <div
            className="bottom-0 pointer-events-none absolute w-full"
            style={{
              height: "50%",
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)`,
            }}
          ></div>
        </div>
      )}
    </Layout>
  )
}
