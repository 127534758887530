import React, { useState } from "react"
import Layout from "./ComponentLayout"
import { FAQComponent } from "../components/index"

export const FAQWithText = ({ header, title, body, componentDepth, faq = [] }) => {
  const [active, setActive] = useState(0)
  return (
    <Layout componentDepth={componentDepth}>
      <div className="overflow-hidden py-20">
        <div className="text-center md:text-left container mx-auto px-5 mb-12">
          <h2 className="font-sans text-light-grey-1 uppercase flex items-center tracking-widest mb-10 text-xs md:text-base font-medium">
            <span className="inline-block border-t border-light-grey-2 w-5 md:w-10 lg:w-30 mr-5"></span>{" "}
            {header}
          </h2>
        </div>
        <div className="container mx-auto md:flex">
          <div className="px-5 lg:w-2/5">
            {title && (
              <h2 className="text-xl sm:text-2xl xl:text-4xl text-left leading-tight uppercase mb-8">
                {title}
              </h2>
            )}
          </div>

          <div className="container px-5 mx-auto flex lg:w-1/2">
            {faq && (
              <FAQComponent
                body={body}
                faq={faq}
                index={0}
                active={active}
                setActive={setActive}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
