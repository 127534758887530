import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const Button = ({
  href,
  children,
  onClick = () => {},
  className = "",
  type = "",
}) => {
  // If there isn't a link or a text then don't show
  if (!children && !href) {
    return null
  }

  const external = href.indexOf("http") === 0
  return (
    <>
      {external ? (
        <a
          className={`cursor-pointer button ${type} ${className}`}
          href={href}
          onClick={onClick}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      ) : (
        <AniLink
          cover
          direction="right"
          duration={1.5}
          bg="#000000"
          className={`cursor-pointer button ${type} ${className}`}
          to={href}
          onClick={onClick}
          rel="noreferrer"
        >
          {children}
        </AniLink>
      )}
    </>
  )
}
