import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/media-slider.css"
import "../styles/play-icon.css"
import { useMediaQuery } from "react-responsive"
import YouTube from "react-youtube"
import { Modal, PlayIcon, Section } from "./"
import Layout from "../components/ComponentLayout"
import "../styles/media-slider.css"
import { GatsbyImage } from "gatsby-plugin-image"

import { HoverAnimate } from "./HoverAnimate"
import { HoverArrow } from "./"

const youtubeSettings = {
  height: "100%",
  width: "100%",
  playerVars: {
    autoplay: 1,
  },
}

export const MediaSlider = ({
  heading,
  slides,
  overflow,
  tearTop,
  tearBottom,
  componentDepth,
  backgroundType,
  backgroundVideo,
  backgroundColour,
  backgroundImage,
  topTear,
  bottomTear,
  section,
  filters,
}) => {
  const [modalContent, setModalContent] = React.useState(<div></div>)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [currentTag, setCurrentTag] = React.useState("All")
  const [dragging, setDragging] = React.useState(false)

  // Work out the total count of the slides after filtering
  const totalCount = slides.reduce((total, slide) => {
    return currentTag === "All" || slide.tag === currentTag ? total + 1 : total
  }, 0)
  const sliderRef = React.useRef(null)

  // Pass false for previous
  const changeSlide = next => {
    if (next) {
      sliderRef.current.slickNext()
    } else {
      sliderRef.current.slickPrev()
    }
  }

  const isMobile = useMediaQuery({
    query: "(max-width: 1023px)",
  })
  const slickSettings = {
    // Large desktop
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    infinite: true,
    arrows: false,
    draggable: true,
    focusOnSelect: true,
    variableWidth: true,
    beforeChange: (current, next) => {
      setDragging(true)
      setCurrentSlide(next)
    },
    afterChange: (current, next) => {
      setDragging(false)
    },
    responsive: [
      // Mobile and tablet
      {
        breakpoint: 768,
        settings: {
          focusOnSelect: false,
          variableWidth: false,

          // Emulating non-center mode so I can still use the .slick-center class
          centerMode: true,
          centerPadding: 0,
        },
      },
    ],
  }

  return (
    <Layout
      backgroundType={backgroundType}
      backgroundVideo={backgroundVideo}
      backgroundColour={backgroundColour}
      backgroundImage={backgroundImage}
      componentDepth={componentDepth}
      overflow={overflow}
      tearTop={tearTop}
      tearBottom={tearBottom}
      topTear={topTear}
      bottomTear={bottomTear}
    >
      <div className="container mx-auto px-5">
        <Section>{section}</Section>
        <div className="flex justify-between items-center pb-10 overflow-x-scroll md:overflow-x-visible">
          <ul className="flex">
            {filters.map((filter, filterIndex) => {
              return (
                <li
                  key={`secondary-nav-anchor-${filterIndex}`}
                  className={`xl:w-auto mr-1 overflow-hidden`}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentSlide(0)
                      setCurrentTag(filter)
                    }}
                    className={`block font-sans uppercase py-4 px-8 ${
                      currentTag === filter
                        ? "border-animate border-animate-bottom"
                        : "border-animate-bottom-hover border-b border-light-grey-2"
                    } text-sm z-0`}
                  >
                    {filter}
                  </button>
                </li>
              )
            })}
          </ul>

          {/* NOTE: This is copied and pasted in 2 places on this page for responsiveness. But if any changes need to be made, then best to convert to a component */}
          {/* Use custom arrows with custom change slide function to override default slick functionality */}
          {slides && slides.length > 1 ? (
            <div
              className="hidden lg:flex container mx-auto px-5 lg:px-12 justify-center items-center lg:justify-end mt-3 font-sans"
              // style={{ bottom: "-20%", right: "12%" }}
            >
              <div className="hidden lg:block text-sm mr-5">
                {currentSlide + 1} / {totalCount}
              </div>
              <button
                className="inline-block"
                type="button"
                onClick={() => {
                  changeSlide(false)
                }}
              >
                <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                  <HoverArrow direction="left" size="small" />
                </HoverAnimate>
              </button>
              <button
                className="inline-block ml-3"
                type="button"
                onClick={() => {
                  changeSlide(true)
                }}
              >
                <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                  <HoverArrow direction="right" size="small" />
                </HoverAnimate>
              </button>
            </div>
          ) : null}
        </div>
      </div>

      <div className="relative media-slider">
        {slides && (
          <Slider {...slickSettings} ref={sliderRef}>
            {/* Make sure to filter out the slides first, so the index is correct in the map */}
            {slides
              .filter(
                slide => currentTag === "All" || currentTag === slide?.tag
              )
              .map((slide, index) => {
                return (
                  <div key={`media-slide-${index}`} style={{ width: 738 }}>
                    <MediaSlide
                      slide={slide}
                      index={index}
                      dragging={dragging}
                      currentSlide={currentSlide}
                      key={`media-slider-${index}`}
                      setModalOpen={setModalOpen}
                      setModalContent={setModalContent}
                      isMobile={isMobile}
                    />
                  </div>
                )
              })}
          </Slider>
        )}
      </div>

      {/* Use custom arrows with custom change slide function to override default slick functionality */}
      {slides && slides.length > 1 ? (
        <div
          className="flex lg:hidden container mx-auto px-5 lg:px-12 justify-center items-center sm:justify-end mt-3 font-sans"
          // style={{ bottom: "-20%", right: "12%" }}
        >
          <div className="hidden sm:block text-sm mr-5">
            {currentSlide + 1} / {totalCount}
          </div>
          <button
            className="inline-block"
            type="button"
            onClick={() => {
              changeSlide(false)
            }}
          >
            <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
              <HoverArrow direction="left" size="small" />
            </HoverAnimate>
          </button>
          <button
            className="inline-block ml-3"
            type="button"
            onClick={() => {
              changeSlide(true)
            }}
          >
            <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
              <HoverArrow direction="right" size="small" />
            </HoverAnimate>
          </button>
        </div>
      ) : null}
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth={true}
      >
        {modalContent}
      </Modal>
    </Layout>
  )
}

function MediaSlide({
  slide: {
    placeholder,
    thumbnail,
    thumbnailVideo,
    caption,
    youtubeId,
    fullSizeImage,
  },
  index,
  dragging,
  currentSlide,
  setModalContent,
  setModalOpen,
  isMobile,
}) {
  const modalContent = youtubeId ? (
    <YouTube
      containerClassName="embed-responsive-wrapper"
      videoId={youtubeId}
      opts={youtubeSettings}
      style={{ height: "100%" }}
    />
  ) : (
    <div className="flex justify-center items-center h-full embed-responsive-wrapper">
      {/* <img
        src={
          fullSizeImage?.childImageSharp?.gatsbyImageData.images.fallback.src
        }
        alt={fullSizeImage?.altText}
        srcSet={
          fullSizeImage?.childImageSharp?.gatsbyImageData.images.fallback.srcSet
        }
        className="object-contain h-auto"
      /> */}
      <GatsbyImage
        image={thumbnail?.childImageSharp?.gatsbyImageData}
        objectFit="contain"
        className="absolute inset-0 embed-responsive-item"
        alt="Full size modal media"
      />
    </div>
  )

  return (
    <div>
      <div className=" mx-5 lg:mx-12">
        <figure className="rounded origin-center" key={index}>
          <div className="media-slider-content relative border border-frame-border">
            {/* If a youtube ID is provided, add a play button that opens a modal */}
            {/* Else, clicking anywhere on the slide should open the full size image */}
            {youtubeId ? (
              <>
                {placeholder === "Image" && (
                  <div className="embed-responsive-wrapper">
                    <div className="embed-responsive-item bg-center bg-cover">
                      <GatsbyImage
                        image={thumbnail?.childImageSharp?.gatsbyImageData}
                        objectFit="cover"
                        className="absolute inset-0"
                        alt="YouTube thumbnail"
                      />
                    </div>
                  </div>
                )}
                {placeholder === "Video" && (
                  <video autoPlay muted loop className="object-fit">
                    <source src={thumbnailVideo?.publicURL} type="video/webm" />
                    <source src={thumbnailVideo?.publicURL} type="video/mp4" />
                  </video>
                )}
                <div
                  className="centered-button"
                >
                  <PlayIcon onClick={() => {
                    if (currentSlide !== index || dragging) {
                      return
                    }
                    setModalContent(modalContent)
                    setModalOpen(true)
                  }} />
                </div>
              </>
            ) : (
              <button
                className="block w-full"
                type="button"
                onClick={() => {
                  if (currentSlide !== index || dragging) {
                    return
                  }
                  setModalContent(modalContent)
                  setModalOpen(true)
                }}
              >
                <div className="embed-responsive-wrapper">
                  <div className="embed-responsive-item bg-center bg-cover">
                    <GatsbyImage
                      image={thumbnail?.childImageSharp?.gatsbyImageData}
                      objectFit="cover"
                      className="absolute inset-0"
                      alt="Media thumbnail"
                    />
                  </div>
                </div>
              </button>
            )}
          </div>

          <figcaption className="uppercase font-sans tracking-widester text-xs sm:text-sm py-5">
            <span>{caption}</span>
          </figcaption>
        </figure>
      </div>
    </div>
  )
}
