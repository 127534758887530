import React from "react"
import Layout from "./ComponentLayout"
import { Section } from "./Section"

export const Download = ({ title, body, componentDepth }) => {
  // Null is returned when no buttons are present, which doesn't fallback to default function parameter value
  // const buttonsArray = buttons || []

  return (
    <Layout componentDepth={componentDepth}>
      <div className="overflow-hidden">
        <div className="container mx-auto px-5">
        <Section>Community</Section>
        <div className="lg:flex">
          <div className="lg:w-1/2">
            {title && (
              <h2 className="text-xl md:text-h2 leading-tight uppercase mb-12 w-4/5">
                {title}
              </h2>
            )}
          </div>
          <div className="w-full lg:w-1/2">
            {body && (
              <p className="font-sans font-normal leading-loose text-lg md:text-3lg text-white mb-10">
                {body}
              </p>
            )}
          </div>
        </div>
        </div>
      </div>
    </Layout>
  )
}
