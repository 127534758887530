import React from "react"

export const PlayIcon = ({ className, onClick = () => {} }) => {
  return (
    <button
      onClick={onClick}
      className={`play-icon -m-1 button-instant w-14 h-14 rounded-full overflow-hidden border border-solid border-white cursor-pointer ${className}`}
    >
      <svg
        width="24"
        height="20"
        viewBox="0 0 19 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="play-triangle ml-0.5"
      >
        <path
          d="M1 12.7362V23.8362L17.5 12.7362L1 1.63623V12.7362Z"
          stroke="white"
        />
      </svg>
      <div className="sr-only">Play video button</div>
    </button>
  )
}
