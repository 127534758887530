import React from "react";

const RefContext = React.createContext();

function RefProvider({ children }) {
  const [refs, setRefs] = React.useState([]);
  return (
    <RefContext.Provider value={{ refs, setRefs }}>
      {children}
    </RefContext.Provider>
  );
}

function useRefs() {
  const context = React.useContext(RefContext);
  if (context === undefined) {
    throw new Error("useRefs must be used within a RefProvider.");
  }
  return context;
}

export { RefProvider, useRefs };
