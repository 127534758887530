import React from "react";
import ReactModal from "react-modal";
import { CloseButton } from "./";
import "../styles/modal.css";

ReactModal.setAppElement("#___gatsby");

export const Modal = ({
  children,
  isOpen = false,
  fullHeight = false /* always fill the space */,
  fullWidth = false,
  onClose = () => {
    return;
  },
  contentLabel = "",
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={onClose}
      closeTimeoutMS={300}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.8)",
          zIndex: 100,
        },
        content: {
          /* Styles applied to the class rather than through JS */
        },
      }}
    >
    <div className="p-8 h-full overflow-hidden">
        <div className="fixed z-50 ca-react-modal__close-wrapper top-0 right-0">
          <button
            className="transition duration-300 hover:opacity-75 p-5"
            onClick={onClose}
          >
            <CloseButton />
          </button>
        </div>
        {/* Wrapping divs used for modal effect */}
        <div
          className={`ca-modal ca-react-modal ${
            fullHeight ? "ca-react-modal--full-height" : ""
          } ${fullWidth ? "ca-react-modal--full-width" : ""}`}
        >
          <div className="ca-modal__inner h-full">{children}</div>
        </div>
      </div>
    </ReactModal>
  );
};
