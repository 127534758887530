import React from "react"
import "../styles/play-icon.css"
import "../styles/spotlight.css"
import Layout from "../components/ComponentLayout"
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "../components/Button"
import Particles from "react-particles-js"
import ParticlesConfig from "../../particlesjs-config.json"

export const Spotlight = ({
  image,
  imageAlt,
  backgroundType,
  backgroundVideo,
  backgroundImage,
  bottomTear,
  topTear,
  button,
  particles,
}) => {
  return (
    <Layout
      backgroundType={backgroundType}
      backgroundVideo={backgroundVideo}
      backgroundImage={backgroundImage}
      bottomTear={bottomTear}
      topTear={topTear}
    >
      <div className={`w-full flex flex-col items-center justify-end relative ${image ? "min-h-screen" : "min-h-threequarterscreen"}`}>
        <div
          className="absolute h-full z-10 bottom-0 left-0 right-0"
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%)",
          }}
        ></div>

        {image && <div className="font-sans text-xs tracking-widest uppercase transform -rotate-90 flex items-center mb-20 md:absolute bottom-0 right-0 z-20">
          <div className="relative mr-5" style={{ width: "50px" }}>
            <div className="scroll-indicator"></div>
          </div>
          Scroll
        </div>}
        {particles && (
          <Particles
            className="z-10 absolute inset-0"
            params={ParticlesConfig}
          />
        )}


        <div className="relative z-20">
          {image?.childImageSharp?.gatsbyImageData && <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            className="w-78 lg:w-108"
            alt={imageAlt} />}

          <div className="flex z-20 mb-10 justify-center">
            {button?.buttonText && button?.buttonLink && (
              <Button
                href={button?.buttonLink}
                type={button.buttonStyle}
                className="uppercase w-2/3 mb-5"
              >
                {button?.buttonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
