import React from "react"
import Layout from "./ComponentLayout"
import WindowsIcon from "../assets/images/icons/windows.svg"
import MacIcon from "../assets/images/icons/apple.svg"
import SteamLinuxIcon from "../assets/images/icons/steam-linux.svg"
import useWindowSize from "../hooks/useWindowSize"

// Fade out table, change data, fade in
const fadeTable = (setValue, value, setShowTable) => {
  setShowTable(false)
  setTimeout(() => {
    setValue(value)
    setShowTable(true)
  }, 150)
}

export const SystemRequirements = ({
  title,
  backgroundImage,
  topTear,
  componentDepth,
  bottomTear,
  overflow,
  mac = [],
  windows = [],
  steam = [],
}) => {
  // TODO: Remove this line when the component is ready to set live

  const [os] = React.useState("windows")
  const [isMobile, setIsMobile] = React.useState(false)
  const [requirement, setRequirement] = React.useState("minimum")
  const [showTable, setShowTable] = React.useState(true)
  const size = useWindowSize()
  const icon = {
    windows: WindowsIcon,
    mac: MacIcon,
    steam: SteamLinuxIcon,
  }

  React.useEffect(() => {
    setIsMobile(size.width <= 680)
  }, [size])

  const requirements = { mac, windows, steam }
  const currentRequirements = requirements[os]

  return (
    <Layout
      topTear={topTear}
      bottomTear={bottomTear}
      overflow={overflow}
      componentDepth={componentDepth}
      backgroundType="Image"
      backgroundImage={backgroundImage}
      fullWidth={false}
    >
      <div className="mt-8 max-w-6xl mx-auto px-5">
        <div className="flex flex-col">
          <h2 className="font-inter text-light-grey-1 uppercase flex items-center tracking-widest mb-6">
            <span className="inline-block border-t border-light-grey-2 w-20 mr-5"></span>{" "}
            Requirements
          </h2>
          {/* <div className="flex font-inter text-base sm:justify-end my-0 w-threequarterscreen w-full sm:w-1/2 justify-center">
            <a
              href="#system-requirements-table"
              className={`${
                os === "windows"
                  ? "border-animate border-animate-bottom border-transparent"
                  : "border-animate-bottom-hover border-dark-grey-1"
              } border-b text-center cursor-pointer flex-grow pb-2`}
              onClick={event => {
                event.preventDefault()
                fadeTable(setOs, "windows", setShowTable)
              }}
            >
              WINDOWS
            </a>
            <a
              href="#system-requirements-table"
              className={`${
                os === "mac"
                  ? "border-animate border-animate-bottom border-transparent"
                  : "border-animate-bottom-hover border-dark-grey-1"
              } border-b text-center cursor-pointer flex-grow pb-2 ml-2`}
              onClick={event => {
                event.preventDefault()
                fadeTable(setOs, "mac", setShowTable)
              }}
            >
              MAC OSX
            </a>
            <a
              href="#system-requirements-table"
              className={`${
                os === "steam"
                  ? "border-animate border-animate-bottom border-transparent"
                  : "border-animate-bottom-hover border-dark-grey-1"
              } border-b text-center cursor-pointer flex-grow pb-2 ml-2`}
              onClick={event => {
                event.preventDefault()
                fadeTable(setOs, "steam", setShowTable)
              }}
            >
              STEAM OS + LINUX
            </a>
          </div> */}
        </div>

        <div className="font-sans my-5 wrap">
          {isMobile ? (
            <div>
              <div className="flex justify-center mb-5">
                <a
                  href="#system-requirements-table"
                  className={`border-b-2 ${
                    requirement === "minimum"
                      ? "border-gold"
                      : "border-dark-grey-1"
                  } hover:border-gold cursor-pointer flex-grow text-center pb-2 text-sm uppercase`}
                  onClick={event => {
                    event.preventDefault()
                    fadeTable(setRequirement, "minimum", setShowTable)
                  }}
                >
                  Minimum
                </a>
                <a
                  href="#system-requirements-table"
                  className={`border-b-2 ${
                    requirement === "recommended"
                      ? "border-gold"
                      : "border-dark-grey-1"
                  } hover:border-gold cursor-pointer flex-grow text-center ml-3 pb-2 text-sm uppercase`}
                  onClick={event => {
                    event.preventDefault()
                    fadeTable(setRequirement, "recommended", setShowTable)
                  }}
                >
                  Recommended
                </a>
              </div>
              <table
                id="system-requirements-table"
                className={`${
                  showTable ? "fade-in" : "fade-out"
                } border border-dark-grey-1 table-fixed bg-black mx-auto w-full`}
              >
                <thead>
                  <tr className="border border-dark-grey-1">
                    <th className="px-4 w-1/5">
                      <img
                        src={icon[os]}
                        alt="Category"
                        className="inline-block"
                      />
                    </th>
                    {requirement === "minimum" ? (
                      <th className="border border-dark-grey-1 px-4 text-left w-2/5 py-4 text-sm font-light tracking-wider uppercase">
                        Minimum
                      </th>
                    ) : (
                      <th className="border border-dark-grey-1 px-4 text-left w-2/5 py-4 text-sm font-light tracking-wider uppercase">
                        Recommended
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentRequirements.map((item, id) => (
                    <tr
                      className="border border-dark-grey-1"
                      key={`${os}-${id}`}
                    >
                      <td className="border border-dark-grey-1 px-4 text-center py-4">
                        {item.value}
                      </td>
                      {requirement === "minimum" ? (
                        <td className="border border-dark-grey-1 px-4 text-light-grey-1 py-4">
                          {item.minimumRequirements}
                        </td>
                      ) : (
                        <td className="border border-dark-grey-1 px-4 text-light-grey-1 py-4">
                          {item.recommendedRequirements}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <table
              className={`${
                showTable ? "fade-in" : "fade-out"
              } border border-dark-grey-1 table-fixed bg-black mx-auto w-full`}
            >
              <thead>
                <tr className="border border-dark-grey-1">
                  <th className="px-4 w-1/5">
                    <img
                      src={icon[os]}
                      className="inline-block"
                      alt="Category"
                    />
                  </th>
                  <th className="border border-dark-grey-1 px-4 text-left w-2/5 py-4 text-sm font-light tracking-wider uppercase">
                    Minimum
                  </th>
                  <th className="border border-dark-grey-1 px-4 text-left w-2/5 py-4 text-sm font-light tracking-wider uppercase">
                    Recommended
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRequirements.map((item, id) => (
                  <tr
                    className="border border-dark-grey-1 py-4"
                    key={`${os}-${id}`}
                  >
                    <td className="border border-dark-grey-1 px-4 text-center py-4">
                      {item.value}
                    </td>
                    <td className="border border-dark-grey-1 px-4 text-light-grey-1 py-4">
                      {item.minimumRequirements}
                    </td>
                    <td className="border border-dark-grey-1 px-4 text-light-grey-1 py-4">
                      {item.recommendedRequirements}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Layout>
  )
}
