import React, { useRef } from "react"
import Slider from "react-slick"
import { HoverArrow } from "."
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/characters-gallery.css"
import "../styles/arrows.css"
import Layout from "./ComponentLayout"
import { HoverAnimate } from "./HoverAnimate"
import { Content } from "./index"
import { GatsbyImage, withArtDirection, getImage } from "gatsby-plugin-image"

// Mouse move
import { useSpring, animated } from "react-spring"

export const CharactersGallery = ({
  characters = [],
  overflow,
  bottomTear,
  topTear,
  componentDepth,
}) => {
  // States
  const [, setCurrentSlide] = React.useState(0)

  const characterRef = useRef(null)
  const contentSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    focusOnSelect: false,
    draggable: true,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next)
    },
    // prevArrow: (
    //   <button>
    //     <HoverAnimate extraClasses="hidden md:block xl:hidden arrow-circle arrow-circle-small">
    //       <HoverArrow direction="left" size="small" />
    //     </HoverAnimate>
    //   </button>
    // ),
    // nextArrow: (
    //   <button>
    //     <HoverAnimate extraClasses="hidden md:block xl:hidden arrow-circle arrow-circle-small">
    //       <HoverArrow direction="right" size="small" />
    //     </HoverAnimate>
    //   </button>
    // ),
    fade: true,
    speed: 800,
  }

  // Pass false for previous
  const changeSlide = next => {
    if (next) {
      characterRef.current.slickNext()
    } else {
      characterRef.current.slickPrev()
    }
  }

  // Set what the mouse movement translates to (opposite movement of mouse position)
  // const translateBackground = (x, y) => `translate3d(${x / 33}px,${y / 33}px,0)`
  const translateForeground = (x, y) =>
    `translate3d(${x / 100}px,${y / 100}px,0)`

  // Set the options for how the image feels when it's moving
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 1, tension: 170, friction: 50 },
  }))

  const mouseSettings = {
    // Calculate mouse move values based on the browser dimensions
    onMouseMove: ({ clientX: x, clientY: y }) =>
      set({
        xy: [-(x - window.innerWidth / 4), -(y - window.innerHeight / 4)],
      }),

    // Reset position on mouse out
    onMouseOut: ({ clientX: x, clientY: y }) => set({ xy: [0, 0] }),
  }

  return (
    <Layout
      overflow={overflow}
      bottomTear={bottomTear}
      topTear={topTear}
      componentDepth={componentDepth}
    >
      <div className="h-full w-full relative">
        <Slider
          {...contentSettings}
          className="slider-characters"
          ref={characterRef}
        >
          {characters.map(
            (
              {
                name,
                race,
                subtitle,
                description,
                desktopImage,
                mobileImage,
                backgroundImage,
                particlesImage,
                factionLogo,
                bottomRip,
              },
              index
            ) => {
              // console.log("desktopImage", desktopImage)
              // console.log('mobileImage', mobileImage);
              return (
                <div key={`character-${index}`} {...mouseSettings}>
                  {/* BG and Character Slide */}
                  <div
                    className="characters-bg overflow-hidden bg-no-repeat relative z-10 w-full h-full flex justify-center bg-cover"
                    style={{
                      backgroundImage: `url(${backgroundImage?.childImageSharp?.gatsbyImageData.images.fallback.src})`,
                    }}
                  >
                    {/* Gradient */}
                    <div
                      className="pointer-events-none absolute inset-0"
                      style={{
                        background:
                          "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 50%)",
                      }}
                    ></div>
                    {/* Bottom Rip */}
                    <div
                      className="absolute bottom-24 w-full transform bg-center"
                      style={{
                        height: 100,
                        backgroundImage: `url("${bottomRip.childImageSharp.gatsbyImageData.images.fallback.src}")`,
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                    {/* Background particles
                    {particlesImage && (
                      <animated.div
                        className="pointer-events-none absolute inset-0"
                        style={{
                          transform: props.xy.interpolate(translateBackground),
                        }}
                      >
                        <img
                          className="absolute w-full h-full z-10 object-cover"
                          src={
                            particlesImage.childImageSharp.gatsbyImageData
                              .images.fallback.src
                          }
                          alt=""
                        />
                      </animated.div>
                    )} */}
                    {/* Character */}
                    {desktopImage && (
                      <animated.div
                        className="relative inset-0 z-20 w-full h-twothirdscreen sm:h-threequarterscreen"
                        style={{
                          transform: props.xy.interpolate(translateForeground),
                        }}
                      >
                        <GatsbyImage
                          image={withArtDirection(
                            getImage(desktopImage),
                            [
                              mobileImage ? {
                                media: "(max-width: 640px)",
                                image: getImage(
                                  mobileImage
                                ),
                              } : {},
                            ]
                          )}
                          className="absolute h-full w-full"
                          objectFit="contain"
                          alt={`${name.join(" ")} character`}
                        />
                      </animated.div>
                    )}
                  </div>
                  {/* Text Content */}
                  <div className="mx-auto md:px-12 px-5 mt-5 lg:container">
                    <div className="md:flex text-center md:text-left">
                      <div className="md:pr-12 lg:w-2/6">
                        {name.length > 0 && (
                          <h3 className="font-serif uppercase text-xl md:text-2xl leading-tight mb-6">
                            {name.map((line, lineIndex) => {
                              return (
                                <span key={`line-${lineIndex}`}>
                                  {line}
                                  {lineIndex < name.length - 1 && <br />}
                                </span>
                              )
                            })}
                          </h3>
                        )}
                        <div className="faction-logos md:flex items-center mb-10">
                          <div className="mb-5 md:m-0">
                            {factionLogo && (
                              <img
                                width="50"
                                src={
                                  factionLogo.childImageSharp.gatsbyImageData
                                    .images.fallback.src
                                }
                                alt={`${race} logo`}
                              />
                            )}
                          </div>
                          <div className="flex flex-col md:pl-3 font-inter font-medium text-xs sm:text-base uppercase leading-tight xl:tracking-wider tracking-widester">
                            {race && (
                              <span className="text-light-grey-1 mb-2 font-medium">
                                {race}
                              </span>
                            )}
                            {subtitle && (
                              <span className="text-white font-medium">
                                {subtitle}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <Content
                        content={description}
                        className="text-base sm:text-normal md:text-lg leading-normal text-white font-inter lg:w-4/6"
                        lastParagraphMargin="mb-5"
                      />
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </Slider>
        {/* Use custom arrows with custom change slide function to override default slick functionality */}
        {characters && characters.length > 1 ? (
          <div className="flex justify-center w-full mb-10 sm:absolute sm:top-33% sm:justify-between relative">
            <div className="flex justify-between sm:container mx-auto sm:px-5 md:px-10">
              {/* <div className="hidden xl:block text-sm mr-5 font-sans">
              {currentSlide + 1} / {characters.length}
            </div> */}
              <button
                className=""
                type="button"
                onClick={() => {
                  changeSlide(false)
                }}
              >
                <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                  <HoverArrow direction="left" size="small" />
                </HoverAnimate>
              </button>
              <button
                className="ml-3"
                type="button"
                onClick={() => {
                  changeSlide(true)
                }}
              >
                <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                  <HoverArrow direction="right" size="small" />
                </HoverAnimate>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  )
}
