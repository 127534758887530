import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import * as Components from "../components"

import Helmet from "react-helmet"

// Add animations to the global page template, as they're used across multiple components
import "../styles/animations/borders.css"
import "../styles/animations/scrolls.css"
import "../styles/animations/buttons.css"
import "../styles/bootstrap.css"
import "../styles/utilities.css"

import FavIcon from "../assets/favicon.ico";

import { graphql } from "gatsby"

export default function MdxPage({
  data,
  pageContext: { body, basePath, frontMatter },
}) {
  const { title, description, socialImage, path } = frontMatter
  const { siteUrl, siteName, siteDescription } = data.site.siteMetadata

  const pageTitle = `${siteName} - ${title}`;

  // Needed for social, to go to individual pages
  const pagePath =`${siteUrl}${path}`;

  return (
    <Components.Layout>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}>
        {/* Favicon */}
        {/* <link rel="apple-touch-icon" href="//d1diae5goewto1.cloudfront.net/_skins/apple-touch-icon-57x57.png" />
	<link rel="apple-touch-icon" sizes="72x72" href="//d1diae5goewto1.cloudfront.net/_skins/touch-icon-ipad.png" />
	<link rel="apple-touch-icon" sizes="114x114" href="//d1diae5goewto1.cloudfront.net/_skins/touch-icon-iphone4.png" />
	<link rel="apple-touch-icon" sizes="120x120" href="//d1diae5goewto1.cloudfront.net/_skins/touch-icon-iphone-retina.png" />
	<link rel="apple-touch-icon" sizes="152x152" href="//d1diae5goewto1.cloudfront.net/_skins/touch-icon-ipad-retina.png" />
	<link rel="shortcut icon" type="image/png" href="//d1diae5goewto1.cloudfront.net/_skins/favicon.png" /> */}

        <title>{pageTitle}</title>
        {/* Search Engine */}
        <meta name="description" content={description || siteDescription} />
        <meta
          name="image"
          content={
            socialImage?.childImageSharp?.gatsbyImageData.images.fallback
              .src || null
          }
        />

        {/* Favicon */}
        <link rel="icon" 
          type="image/png" 
          href={FavIcon} />
        

        {/* Schema.org for Google */}
        <meta itemProp="name" content={pageTitle} />
        <meta itemProp="description" content={description || siteDescription} />
        <meta
          itemProp="image"
          content={`${siteUrl}${
            socialImage?.childImageSharp?.gatsbyImageData.images.fallback
              .src || null
          }`}
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta
          name="twitter:description"
          content={description || siteDescription}
        />
        <meta
          name="twitter:image"
          content={`${siteUrl}${
            socialImage?.childImageSharp?.gatsbyImageData.images.fallback
              .src || null
          }`}
        />
        <meta name="twitter:url" content={`${pagePath}`} />

        {/* Open Graph general (Facebook, Pinterest & Google+) */}
        <meta name="og:title" content={pageTitle} />
        <meta name="og:description" content={description || siteDescription} />
        <meta
          name="og:image"
          content={`${siteUrl}${
            socialImage?.childImageSharp?.gatsbyImageData.images.fallback
              .src || null
          }`}
        />
        <meta name="og:url" content={`${pagePath}`} />
        <meta name="og:site_name" content={siteName} />
        <meta name="og:locale" content="en_US" />
        <meta name="og:type" content="website" />
      </Helmet>
      <Components.HeaderMenu persistLogo={frontMatter.persistLogo} />
      <MDXProvider components={Components}>
        {/* <Components.PrimaryNav anchors={[]} /> */}
        <MDXRenderer basePath={basePath} frontMatter={frontMatter}>
          {body}
        </MDXRenderer>
      </MDXProvider>
      <Components.Footer />
    </Components.Layout>
  )
}

export const query = graphql`
  query NavQuery {
    site {
      siteMetadata {
        siteUrl
        siteName
        siteDescription
      }
    }
  }
`
