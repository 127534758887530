import React from "react";
import Accordion from "react-bootstrap/Accordion";
import PlusIcon from "../assets/images/icons/plus.svg";
import { Content } from "../components/index";

export const FAQComponent = ({ body = "", faq, index, active, setActive }) => {
  // No need to display anything if there's no questions
  if (!faq || faq.length === 0) {
    return null;
  }

  return (
    <div className="max-w-4xl mx-auto">
      {body && (
        <p className="font-inter font-normal text-lg sm:text-3lg text-white mb-10">
          {body}
        </p>
      )}

      {/* activeKey needs */}
      <Accordion activeKey={active.toString()}>
        <div className="border-b border-solid border-dark-grey-1">
          {faq.map((item, itemIndex) => {
            const faqIndex = `faq-${index}${itemIndex}`;
            return (
              <React.Fragment
                key={`faq-question=${itemIndex}`}
              >
                <Accordion.Toggle
                  onClick={(event) => {
                    setActive(active === faqIndex ? false : faqIndex); // Adds toggle functionality
                  }}
                  as="button"
                  className={`outline-none w-full pt-5 pb-6 transition duration-300 border-t border-dark-grey-1 overflow-hidden ${
                    active === faqIndex
                      ? "border-animate border-animate-top"
                      : "border-animate border-animate-top-out"
                  }`}
                  eventKey={faqIndex}
                >
                  <div className="lg:text-2lg font-inter flex justify-between transition duration-300 hover:opacity-75">
                    <span>{item.question}</span>{" "}
                    <img
                      src={PlusIcon}
                      className={`transition duration-300 ${
                        active === faqIndex ? "transform rotate-45" : ""
                      }`}
                      alt=""
                    />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={faqIndex}>
                  <div>
                    <Content content={item.answer} className="font-inter text-normal lg:text-lg text-light-grey-1" lastParagraphMargin="mb-8" />
                  </div>
                </Accordion.Collapse>
              </React.Fragment>
            );
          })}
        </div>
      </Accordion>
    </div>
  );
};
