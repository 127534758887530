import React from "react"
import { Content } from "./index"
import { Button } from "./Button"
import Layout from "./ComponentLayout"

export const BackgroundWithText = ({
  title,
  body,
  buttons,
  backgroundImage,
  backgroundVideo,
  componentDepth,
  topTear,
  bottomTear,
  textPosition = "left",
}) => {
  return (
    <Layout
      backgroundImage={backgroundImage}
      backgroundVideo={backgroundVideo}
      componentDepth={componentDepth}
      topTear={topTear}
      bottomTear={bottomTear}
      backgroundGradient={textPosition === "left" ? "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(270deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%)" : "linear-gradient(270deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))"}
    >
      <div className="overflow-hidden">
        <div className="container px-5 mx-auto flex ">
          {textPosition === "right" && <div className="lg:w-1/2"></div>}
          <div className={`lg:w-2/5 z-10`}>
            {title && (
              <h2 className="text-xl sm:text-2xl xl:text-4xl text-center lg:text-left leading-tight uppercase mb-8">
                {title}
              </h2>
            )}
            {body && (
              <p className="font-sans font-normal leading-loose text-normal sm:text-lg text-white mb-10">
                <Content
                content={body}                  
                />
              </p>
            )}
            <div className="flex justify-center lg:justify-start">
              {buttons.map((button, buttonIndex) => (
                <Button
                  key={`button-${buttonIndex}`}
                  bgColour="button"
                  href={`${button?.buttonLink}`}
                  className="px-16 w-full sm:w-auto sm:mb-0 tracking-widest"
                  type={button?.buttonStyle}
                >
                  {button?.buttonText}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
