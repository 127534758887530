import React from "react";

export const CloseButton = ({ className }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <title>Close</title>
      <path d="M4.84086 5.76583L5.76586 4.84083C5.84808 4.75861 5.92847 4.75678 6.00704 4.83535L16.3779 15.2063C16.4565 15.2848 16.4547 15.3652 16.3725 15.4474L15.4475 16.3724C15.3652 16.4547 15.2848 16.4565 15.2063 16.3779L4.83538 6.00701C4.75681 5.92844 4.75864 5.84805 4.84086 5.76583Z" fill="white" />
      <path d="M4.86698 15.2268L15.2269 4.86683C15.3091 4.78461 15.3895 4.78278 15.4681 4.86135L16.352 5.74523C16.4306 5.8238 16.4287 5.90419 16.3465 5.98641L5.98657 16.3464C5.90435 16.4286 5.82395 16.4304 5.74539 16.3518L4.8615 15.4679C4.78294 15.3894 4.78476 15.309 4.86698 15.2268Z" fill="white" />
    </svg>
  );
};
