import React from "react"

export default function ComponentLayout({
  children,
  overflow = "",
  fullWidth = true,
  className = "",
  backgroundVideo = null,
  backgroundColour = null,
  backgroundImage = null,
  componentDepth = "", // Default to normal height of component
  topTear = null,
  bottomTear = null,
  backgroundGradient = null,
  style = {},
}) {
  
  // Add background colour to the style component (which can be passed in)
  if (backgroundColour) {
    style.backgroundColor = backgroundColour
  }

  if (backgroundImage) {
    style.backgroundImage = `url('${backgroundImage.childImageSharp.gatsbyImageData.images.fallback.src}')`
  }

  return (
    // The background image or colour should always go full width, so isn't constrained by fullWidth boolean

    <div
      style={style}
      className={`${calculateOverflow(
        overflow
      )} clear-both float-left ${className} flex items-center justify-center bg-cover bg-top relative w-full z-10 ${calculateDepth(
        componentDepth
      )} overflow-hidden`}
    >
      {/* Background gradient to sit over video and background image */}
      {backgroundGradient && (
        <div
          className="pointer-events-none z-20 object-cover absolute w-full h-full"
          style={{
            background: backgroundGradient,
          }}
        ></div>
      )}

      {backgroundVideo && (
        <BackgroundVideoComponent backgroundVideo={backgroundVideo} />
      )}

      <TearComponent tear={topTear} placement="Top" />
      <div
        className={`relative w-full h-full z-30 ${
          !fullWidth ? "px-5 container mx-auto relative" : ""
        }`}
      >
        {children}
      </div>
      <TearComponent tear={bottomTear} placement="Bottom" />
    </div>
  )
}

const BackgroundVideoComponent = ({ backgroundVideo }) => {
  return (
    <>
      <video
        autoPlay
        muted
        loop
        playsInline
        className="background-video z-10 object-cover absolute w-full h-full pointer-events-none"
      >
        {backgroundVideo?.webm?.publicURL && <source src={backgroundVideo.webm.publicURL} type="video/webm" />}
        {backgroundVideo?.mp4?.publicURL && <source src={backgroundVideo.mp4.publicURL} type="video/mp4" />}
      </video>
    </>
  )
}

// const BackgroundImageComponent = ({ backgroundImage }) => {
//   return (
//     <>
//       <Img
//         fluid={backgroundImage.localFile.childImageSharp.fluid}
//         alt={backgroundImage.altText}
//         className="z-10 object-cover absolute w-full h-full"
//       />
//     </>
//   );
// };

const TearComponent = ({ tear, placement }) => {
  if (!tear) return <></>

  if (placement === "Top") {
    return (
      <div
        className="absolute z-30 top-0 w-full transform pointer-events-none bg-center"
        style={{
          height: 100,
          backgroundImage: `url("${tear.childImageSharp.gatsbyImageData.images.fallback.src}")`,
          backgroundSize: "auto 100%",
        }}
      ></div>
    )
  }

  if (placement === "Bottom") {
    return (
      <div
        className="absolute z-30 bottom-0 w-full transform pointer-events-none bg-center"
        style={{
          height: 100,
          backgroundImage: `url("${tear.childImageSharp.gatsbyImageData.images.fallback.src}")`,
          backgroundSize: "auto 100%",
        }}
      ></div>
    )
  }
}

// Convert the "overflows" value from the component into a margin value
function calculateOverflow(overflow) {
  switch (overflow) {
    case "Top":
      return "-mt-24"
    case "Bottom":
      return "-mb-24"
    case "Both":
      return "-my-24"
    default:
      return ""
  }
}

function calculateDepth(componentDepth) {
  switch (componentDepth) {
    case "XL":
      return "min-h-screen"
    case "L":
      return "min-h-threequarterscreen"
    case "M":
      return "min-h-twothirdscreen"
    case "S":
      return "min-h-halfscreen"
    default:
      return ""
  }
}
