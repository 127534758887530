import React from "react";
import { normalizeNewLines } from "../utils/normalizeNewLines";

// Normalise new lines from a textarea before outputting as JSX
export const Content = ({ content, className, lastParagraphMargin = "mb-0"}) => {

  // Return null if there's no content passed to the component
  if (!content || content.length === 0) {
    return null;
  }

  // Normalise the new lines across different operating systems
  // Split by \n
  // Filter any empty array items
  const paragraphs = normalizeNewLines(content).split("\n").filter(Boolean);

  // Loop over the paragraphs array, and output as JSX
  // Last paragraph may have different margin, so pass this into the component
  // NOTE: mb-5 is using TailwindCSS, so alter for your needs
  return paragraphs.map((paragraph, paragraphIndex) => (
    <p
    key={`content-paragragh-${paragraphIndex}`}
      className={`${className} ${
        paragraphIndex < paragraphs.length - 1 ? "mb-5" : lastParagraphMargin
      }`}
    >
      {paragraph}
    </p>
  ));
};
