import React, { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/total-war-access.css"
import { HoverArrow } from "./"
import Layout from "./ComponentLayout"

import { GatsbyImage } from "gatsby-plugin-image"
import { HoverAnimate } from "./HoverAnimate"
import { Button, Content } from "../components/index"

export const TotalWarAccess = ({
  overflow,
  backgroundColour,
  tearBottom,
  tearTop,
  slides = [],
  bottomTear,
  topTear,
}) => {
  // Element references
  const sliderRef = React.useRef(null)

  // States
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [direction, setDirection] = useState()

  // Pass false for previous
  const changeSlide = next => {
    if (next) {
      sliderRef.current.slickNext()
    } else {
      sliderRef.current.slickPrev()
    }
  }

  const slickSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "100px",
    // centerMode: true,
    // fade: true,
    focusOnSelect: true,
    // autoplay: true,
    // autoplaySpeed: 5000,
    infinite: true,
    draggable: true,
    arrows: false,
    responsive: [
      // breakpoint acts as max-width
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 769,
        settings: {
          centerMode: true,
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerMode: false,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      // console.log(oldIndex > newIndex ? "left" : "right");
      setDirection(oldIndex > newIndex ? "left" : "right")
      setCurrentSlide(oldIndex)
      // setTimeout(() => {
      //   sliderRef.current.slickGoTo();
      // }, 500);
      setTimeout(() => {
        // sliderRef.current.slickNext();
        setCurrentSlide(newIndex)
      }, 300)
    },
  }
  return (
    <Layout
      overflow={overflow}
      backgroundType="Hex"
      backgroundColour={backgroundColour}
      tearTop={tearTop}
      tearBottom={tearBottom}
      topTear={topTear}
      bottomTear={bottomTear}
    >
      <div className="relative slider-total-war">
        {slides && (
          <Slider {...slickSettings} ref={sliderRef}>
            {slides.map((slide, slideIndex) => (
              <TWSlide
                slide={slide}
                currentSlide={currentSlide}
                slideIndex={slideIndex}
                direction={direction}
                tearTop={tearTop}
                tearBottom={tearBottom}
                key={`total-war-access-slider-${slideIndex}`}
              />
            ))}
          </Slider>
        )}
        {/* Use custom arrows with custom change slide function to override default slick functionality */}
        {slides && slides.length > 1 ? (
          <div className="bottom-0 z-40 w-full md:w-auto text-center md:text-right flex justify-center sm:justify-end items-center font-sans md:pr-12 p-5 md:right-0 lg:absolute lg:pr-44 lg:h-36">
            <div className="hidden sm:block text-sm mr-5">
              {currentSlide + 1} / {slides.length}
            </div>
            <button
              className="inline-block"
              type="button"
              onClick={() => {
                changeSlide(false)
              }}
            >
              <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                <HoverArrow direction="left" size="small" />
              </HoverAnimate>
            </button>
            <button
              className="inline-block ml-3"
              type="button"
              onClick={() => {
                changeSlide(true)
              }}
            >
              <HoverAnimate extraClasses="block arrow-circle arrow-circle-small">
                <HoverArrow direction="right" size="small" />
              </HoverAnimate>
            </button>
          </div>
        ) : null}
      </div>
    </Layout>
  )
}

function TWSlide({
  slide: { body, image, link, linkText, logo, title },
  currentSlide,
  slideIndex,
  direction,
  tearTop,
  tearBottom,
}) {
  let fading = currentSlide === slideIndex

  return (
    <div>
      <div className="h-full slide-total-war px-2.5 sm:px-5">
        <div className="overflow-hidden relative h-full w-full flex flex-col">
          
          <div className="z-10 absolute pointer-events-none inset-0" style={{ background: "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.96%, rgba(0, 0, 0, 0.6) 100%)"}}></div>
          
          {image && (
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt="" // NOTE: Text contains all information
              className={`h-threequarterscreen object-cover w-full slide-total-war__background`}
            />
          )}
          <div
            className={`text-center md:text-left slide-total-war__content absolute top-0 w-full z-10 flex flex-col items-center sm:items-start h-full transition duration-300 mt-auto px-5 md:p-12 ${
              fading ? "slide-fade-in" : "slide-fade-out"
            }`}
          >
            <div className="flex-grow">
              {logo && (
                <div className="h-8 md:h-12 mt-5 md:mt-0">
                  <img
                    className="max-h-full"
                    src={
                      logo.childImageSharp.gatsbyImageData.images.fallback.src
                    }
                    alt={logo.altText || "Total war access logo"}
                  />
                </div>
              )}
            </div>
            <div className="mb-5 md:mb-0">
              <h3 className="font-serif text-xl md:text-2xl leading-tight uppercase mb-8 sm:text-left">
                {title}
              </h3>

              <Content
                content={body}
                className="font-sans md:text-lg font-light text-normal sm:text-left"
                lastParagraphMargin="mb-10"
              />

              {link && linkText && (
                <div className="w-auto">
                  <Button
                    href={link}
                    type="button"
                    className="uppercase w-full md:w-auto md:px-16"
                  >
                    {linkText}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
