import React from "react";
import "../styles/arrows.css";

const chooseArrow = ({ className, direction, size }) => {
  switch (size) {
    case "small":
      return (
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: `rotate(${direction === "left" ? "180deg" : "0"})`,
          }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.16667 12.4915L14 6.65817V5.83333L8.16667 0L7.34183 0.824833L12.1788 5.663H0V6.82967H12.18L7.34067 11.6667L8.1655 12.4915H8.16667Z"
          />
        </svg>
      );

    default:
      return (
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className={className}
          style={{
            transform: `rotate(${direction === "left" ? "180deg" : "0"})`,
          }}
        >
          <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
        </svg>
      );
  }
};

export const HoverArrow = ({
  className,
  direction = "right",
  size = "normal",
}) => {
  return <>{chooseArrow({ className, direction, size })}<span className="sr-only">Hover arrow icon</span></>
};
