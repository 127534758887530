import React from "react"
import "../styles/tailwind.css"

import { NewsletterPopup } from "./index"

export const Layout = ({ children }) => {
  return (
    <>
      <main role="main" className="font-serif text-base leading-normal bg-black text-white relative">
        {children}
        {/* Needed as all components are now floated left to avoid margin collapse */}
        <div className="clear-both"></div>
        {/* Newsletter signup modal */}
        <NewsletterPopup />
      </main>
    </>
  )
}
