import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function getHeightClasses(height) {
  switch (height) {
    case 20:
      return `h-10px md:h-20px`
    case 30:
      return `h-20px md:h-30px`
    case 40:
      return `h-30px md:h-40px`
    case 50:
      return `h-40px md:h-50px`
    case 60:
      return `h-50px md:h-60px`
    case 70:
      return `h-60px md:h-70px`

    default:
      return ""
  }
}

export const Footer = () => {
  const {
    ratingImage,
    warhammerLogo,
    caLogo,
    twLogo,
    segaLogo,
    faceLogo,
    instaLogo,
    twitLogo,
    site: {
      siteMetadata: {
        footer: { complianceLinks, logos, socialLinks },
      },
    },
  } = useStaticQuery(footerQuery)

  const logoImages = [warhammerLogo, caLogo, twLogo, segaLogo]
  const socialLogos = {
    Facebook: faceLogo,
    Twitter: twitLogo,
    Instagram: instaLogo,
  }

  return (
    <footer>
      <div className="mx-auto px-5 overflow-hidden border-t border-light-grey-border">
        <div className="text-center">
          <a
            className="transition duration-300 hover:opacity-75"
            href="https://www.esrb.org/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="inline-block my-14"
              src={
                ratingImage.childImageSharp.gatsbyImageData.images.fallback.src
              }
              width="200"
              alt="Rating pending - May contain content inappropriate for children. Visit esrb.org for rating information."
            />
          </a>
          <div className="w-full lg:flex justify-between">
            <ul className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-5 lg:gap-12 text-center mx-auto items-center">
              {logos.map(({ image, imageAlt, link, height }, idx) => (
                <li key={`logo-${idx}`}>
                  <a
                    className="inline-block transition duration-300 hover:opacity-75"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <img
                        src={
                          logoImages[idx].childImageSharp.gatsbyImageData.images
                            .fallback.src
                        }
                        alt={imageAlt || ""}
                        className={`max-w-none ${getHeightClasses(
                          height
                        )} mb-10`}
                      />
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="mx-auto px-5 md:w-11/12 font-sans pt-14 pb-10 md:flex border-t border-light-grey-border">
        <div className="lg:w-3/12 md:w-2/6 mb-10">
          <ul className="tracking-widest text-white mb-10">
            {complianceLinks.map(({ label, link }, idx) => (
              <li
                className="text-2lg md:flex text-center align-top hover:opacity-80 transition duration-300"
                key={idx}
              >
                <a href={link} target="_blank" rel="noreferrer">
                  {label}
                </a>
              </li>
            ))}
          </ul>
          <ul className="flex justify-center md:justify-start">
            {socialLinks.map(({ platform, link }, idx) => (
              <li className="px-3 md:pr-3 md:pl-0" key={`footer-${idx}`}>
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="transition duration-300 hover:opacity-75"
                >
                  <img
                    src={
                      socialLogos[platform].childImageSharp.gatsbyImageData
                        .images.fallback.src
                    }
                    alt={`Link to Total War: Warhammer 3 ${platform} page`}
                    width="35"
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <p className="text-base text-justify tracking-widest text-light-grey-2 lg:w-9/12 md:w-4/6">
          &copy; Copyright Games Workshop Limited 2022. All rights reserved.
          Warhammer, the Warhammer logo, GW, Games Workshop, The Game of Fantasy
          Battles, the twin-tailed comet logo, and all associated logos,
          illustrations, images, names, creatures, races, vehicles, locations,
          weapons, characters, and the distinctive likeness thereof, are either
          &reg; or TM, and/or &copy; Games Workshop Limited, variably registered around
          the world, and used under licence. Developed by Creative Assembly and
          published by SEGA. Creative Assembly, the Creative Assembly logo,
          Total War and the Total War logo are either registered trademarks or
          trademarks of The Creative Assembly Limited. SEGA and the SEGA logo
          are either registered trademarks or trademarks of SEGA CORPORATION.
          SEGA is registered in the U.S. Patent and Trademark Office. All other
          trademarks, logos and copyrights are property of their respective
          owners.
        </p>
      </div>
    </footer>
  )
}

const footerQuery = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        footer {
          complianceLinks {
            label
            link
          }
          logos {
            height
            imageAlt
            link
          }
          socialLinks {
            link
            platform
          }
        }
      }
    }
    ratingImage: file(relativePath: { eq: "images/esrb.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    warhammerLogo: file(relativePath: { eq: "images/logos/wh_logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    caLogo: file(relativePath: { eq: "images/logos/ca_logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    twLogo: file(relativePath: { eq: "images/logos/tw_logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    segaLogo: file(relativePath: { eq: "images/logos/sega_logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    instaLogo: file(relativePath: { eq: "images/logos/instagram.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    twitLogo: file(relativePath: { eq: "images/logos/twitter.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    faceLogo: file(relativePath: { eq: "images/logos/facebook.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
