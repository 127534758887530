import React, { useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/timeline-slider.css"
import Layout from "./ComponentLayout"
import { Content } from "./index"

export const Timeline = ({
  slides = [],
  overflow,
  componentDepth,
  bottomTear,
  topTear,
  backgroundVideo,
  backgroundGradient = "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 44.27%)"
}) => {
  const slideRef = useRef(null)
  const slideSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    draggable: true,
    fade: true,
    dots: true,
    autoplay: false,
    autoplaySpeed: 3000,
    dotsClass: "timeline-dots flex justify-between container mx-auto pr-3",
    customPaging: index => (
      <button className="timeline-dot transition duration-300 hover:bg-white outline-none">
        <span className="sr-only">{index + 1}</span>
      </button>
    ),
    appendDots: dots => {
      return <ul>{dots}</ul>
    },
    // beforeChange: (oldIndex, newIndex) => {
    //   console.log(oldIndex, newIndex);
    //   console.log(slideRef);
    //  );
    // },
  }
  return (
    <Layout
      overflow={overflow}
      topTear={topTear}
      bottomTear={bottomTear}
      componentDepth={componentDepth}
      backgroundVideo={backgroundVideo}
      backgroundGradient={backgroundGradient}
      className="timeline relative"
    >
      {slides && (
        <Slider {...slideSettings} ref={slideRef}>
          {slides.map(
            ({ title, body }, idx) => {
              return (
                <div key={`slide-${idx}`}>
                  <div
                    className="min-h-screen bg-cover bg-center flex justify-center items-center"
                  >
                    <div className="container mx-auto px-5 h-full lg:flex items-center relative z-50">
                      <div className="w-full lg:w-1/2 xl:w-2/5 md:-mr-image-overlay">
                        <div className="md:mr-auto timeline-content-wrapper">
                          {title && <h2 className="text-xl md:text-2xl leading-tight mb-7 uppercase text-center lg:text-left">
                            {title}
                          </h2>}
                          <div>
                            <Content
                              content={body}
                              className="font-inter text-white text-lg text-center lg:text-left"
                              lastParagraphMargin="mb-0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </Slider>
      )}
    </Layout>
  )
}
