import React from "react"

export default function WindowsHighlightLogo() {
  return (
    <svg
    className="w-5 h-5 sm:w-8 sm:h-8"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2603 0H0V9.88625H10.2603V0ZM22 0H11.7397V9.88625H22V0ZM10.2603 11.2901H0V22H10.2603V11.2901ZM22 11.2901H11.7397V22H22V11.2901Z"
        fill="white"
        className="change-white-to-black"
      />
    </svg>
  )
}
