import React from "react";

// NOTE: "className" is already being used for the slick component
export const HoverAnimate = ({
  href,
  children,
  extraClasses = "",
  className = "",
}) => {
  return (
    <a
      className={`cursor-pointer button-instant relative button-animation-instant ${extraClasses} ${className}`}
      href={href}
    >
      {children}
    </a>
  );
};
